import { useCallback } from "react";
import Icon from "@hyperlocal/vital-icons";
import { useDisclosure } from "../../../../hooks";
import { formatToBRL, IDaysInfo, IItem } from "../../../../utils";
import { DetailsModal } from "../Details/DetailsModal";
import * as Styles from "./SalesPerPeriod.styles";

interface ICardProps {
  item: IDaysInfo;
}

export function ListItem({ item }: ICardProps) {
  const modal = useDisclosure<{
    items: IItem[];
    date: string;
    total: number;
  }>();

  const handleCloseModal = useCallback(() => {
    modal.close();
  }, [modal]);

  return (
    <>
      {Object.entries(item).map(([day, dayInfo]) => (
        <Styles.ListItem key={day}>
          <button
            type="button"
            onClick={() =>
              modal.open({
                items: dayInfo.items,
                date: day,
                total: dayInfo.total,
              })
            }
          >
            <Styles.ListItemInfo>
              <div>
                <p>Recebido em</p>
                <p>{day}</p>
              </div>
              <div>
                <p>Total de vendas</p>
                <p>{formatToBRL(dayInfo.total)}</p>
              </div>

              <Styles.SvgContainer>
                <Icon name="ArrowArrowNoLineRight" />
              </Styles.SvgContainer>
            </Styles.ListItemInfo>
          </button>
        </Styles.ListItem>
      ))}

      <DetailsModal
        isOpen={modal.isOpen}
        onClose={() => handleCloseModal()}
        content={modal.modalProps}
        size={{
          width: 480,
          height: "100%",
        }}
      />
    </>
  );
}
