import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const ListItem = styled.li((props) => ({
  listStyleType: "none",
  cursor: "pointer",
  padding: `${props.theme.spacings.inset.xs} ${props.theme.spacings.stack.x2s} `,

  minWidth: 416,
  gap: 16,
  transition: "height .5s ease-in-out",

  overflow: "hidden",

  "&[data-expanded='true']": {
    height: 320,
    border: `1px solid ${props.theme.palette.primary.main}`,
  },
  "&[data-expanded='false']": {
    height: 73,
    border: `1px solid ${props.theme.palette.neutral.light}`,
  },
  borderRadius: props.theme.borderRadius.sm,

  [EBreakPoints.mobile]: {
    padding: `${props.theme.spacings.inset.xs} ${props.theme.spacings.stack.x3s} `,
    gap: props.theme.spacings.stack.x3s,
    minWidth: "100%",
    width: "100%",
    height: "100%",
    "&[data-expanded='false']": {
      height: 67,
    },
  },
}));

export const IconContainer = styled.div(() => ({
  width: "100%",
  maxWidth: "32px",
}));

export const ListItemHeader = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  height: "100%",
  maxHeight: 73,

  [EBreakPoints.mobile]: {
    gap: 0,
  },

  "& > svg": {
    width: "100%",
    maxWidth: 32,
    "&[data-state='pix']": {
      stroke: props.theme.palette.primary.main,
    },
  },

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    lineHeight: props.theme.lineHeights.default,
  },

  div: {
    // width: "100%",
    display: "flex",
    gap: props.theme.spacings.stack.quarck,
    flexDirection: "column",
  },

  "& div:nth-of-type(2)": {
    "& p:first-of-type": {
      fontSize: props.theme.fontSizes.x2s,
      textTransform: "capitalize",
      width: "fit-content",
    },
    "& p:last-of-type": {
      fontSize: props.theme.fontSizes.xs,
      color: props.theme.palette.neutral.dark,
      width: "fit-content",

      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    [EBreakPoints.mobile]: {
      "& p:last-of-type": {
        fontSize: props.theme.fontSizes.x2s,
        color: props.theme.palette.neutral.dark,
      },
    },
  },

  "& div:last-of-type": {
    alignItems: "flex-end",

    "& p:first-of-type": {
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.regular,
      lineHeight: props.theme.lineHeights.default,
      color: props.theme.palette.neutral.darkest,
    },
    "& p:last-of-type": {
      width: 135,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.default,
      color: props.theme.palette.secondary.darker,
      textAlign: "right",
    },
    [EBreakPoints.mobile]: {
      "& p:last-of-type": {
        fontSize: props.theme.fontSizes.x2s,
      },
    },
  },

  "& button": {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 24,
    height: 24,

    "[data-expanded='true']": {
      transform: "rotate(90deg)",
    },
    "[data-expanded='false']": {
      transform: "rotate(0deg)",
    },

    "& svg": {
      width: 14.65,
      height: 14.65,

      transition: "transform 0.3s ease-in-out",
      "& path": {
        fill: props.theme.palette.neutral.darkest,
      },
    },
  },

  "div:has(svg)": {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",

    width: 32,
    height: 32,

    "& svg": {
      width: 32,
      height: 32,
    },
  },
}));

export const ListItemInfo = styled.div((props) => ({
  marginTop: 10,
  width: "100%",

  alignItems: "center",

  "& div + div": {
    marginTop: props.theme.spacings.stack.nano,
  },

  "& div:last-of-type": {
    "& p:first-of-type": {
      fontFamily: props.theme.fonts.base,
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.medium,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.darkest,
      textAlign: "left",
    },

    "& p:last-of-type": {
      fontFamily: props.theme.fonts.base,
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.regular,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
    },
  },
}));

export const ListItemInfoRow = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  "& div + div": {
    marginTop: props.theme.spacings.stack.nano,
  },

  "& p:first-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.medium,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
    textAlign: "left",
  },

  "& p:last-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.xs,
    fontWeight: props.theme.fontWeights.regular,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.dark,
    textAlign: "right",
    [EBreakPoints.mobile]: {
      fontSize: props.theme.fontSizes.x2s,
    },
  },
}));
