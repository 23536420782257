import { format, isBefore, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Event } from "react-big-calendar";
import { capitalizeFirstLetter } from "../../../../utils";

export const eventPropGetter = (_event: Event, start: Date) => {
  const isPastEvent = isBefore(start, new Date());
  if (isPastEvent) {
    return { className: "rbc-event-past" };
  }

  const isTodayEvent = isToday(start);
  if (isTodayEvent) {
    return { className: "rbc-event-current" };
  }

  return { className: "rbc-event-future" };
};

export const weekDayFormat = (date: Date) => {
  const weekDay = format(date, "EEEE", {
    locale: ptBR,
  });

  if (weekDay.includes("-")) {
    // segunda-feira -> Segunda
    const weekDayFirstPart = weekDay.split("-")[0];

    return capitalizeFirstLetter(weekDayFirstPart);
  }

  return capitalizeFirstLetter(weekDay);
};
