/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, Chips, Input } from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";
import { useFilters } from "../../stores";
import * as Styles from "./Filters.styles";

export function Filters() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [currentState, send] = useFilters();
  const navigate = useNavigate();
  return (
    <Styles.Container>
      <Styles.Body>
        <Styles.HeaderContainer>
          <h4>Por Período</h4>
          {isMobile && (
            <Button
              variant="link"
              type="button"
              onClick={() => send({ type: "CLEAR_FILTERS" })}
            >
              Limpar
            </Button>
          )}
        </Styles.HeaderContainer>
        <Styles.ChipsContainer>
          {currentState.filters.map((item) => (
            <Chips
              key={item}
              onClick={() => {
                send({
                  type: "SET_SELECTED_FILTER",
                  payload: {
                    selectedFilter: item,
                  },
                });
              }}
              active={currentState.selectedFilter === item}
            >
              {item} dias
            </Chips>
          ))}
        </Styles.ChipsContainer>
        <Styles.FiltersContainer>
          <Input
            type="search"
            placeholder="Buscar..."
            value={currentState.search}
            onChange={(e) => {
              send({
                type: "SET_SEARCH",
                payload: {
                  search: e.target.value,
                },
              });
            }}
          />
          <h5>Período personalizado</h5>

          <Styles.DateInputsContainer>
            <Input
              type="date"
              label="De"
              value={currentState?.startDate?.toISOString().split("T")[0]}
              onChangeValue={(e) => {
                send({
                  type: "SET_START_DATE",
                  payload: { startDate: e },
                });
              }}
              helper={currentState.dateErrors.startDate ? "error" : "info"}
              helperText={
                currentState.dateErrors.startDate
                  ? currentState.dateErrors.startDate
                  : ""
              }
            />
            <Input
              type="date"
              label="Até"
              value={currentState?.endDate?.toISOString().split("T")[0]}
              onChangeValue={(e) => {
                send({
                  type: "SET_END_DATE",
                  payload: { endDate: e },
                });
              }}
              helper={currentState.dateErrors.endDate ? "error" : "info"}
              helperText={
                currentState.dateErrors.endDate
                  ? currentState.dateErrors.endDate
                  : ""
              }
            />
          </Styles.DateInputsContainer>
        </Styles.FiltersContainer>
      </Styles.Body>

      <Styles.ButtonContainer>
        {isMobile ? (
          <Button type="button" variant="primary" onClick={() => navigate(-1)}>
            Filtrar
          </Button>
        ) : (
          <Button
            variant="link"
            type="button"
            onClick={() => send({ type: "CLEAR_FILTERS" })}
          >
            Limpar filtro
          </Button>
        )}
      </Styles.ButtonContainer>
    </Styles.Container>
  );
}
