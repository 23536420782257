import { PropsWithChildren, useMemo } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { twMerge } from "tailwind-merge";
import { AnticipationDetails } from "../../api/useAnticipationDetails";

type AnticipationDetailsItemProps = {
  receivable: AnticipationDetails["spotRequestReceivables"][number];
  spotAnticipationRequest: AnticipationDetails["spotAnticipationRequest"];
};

const Info = {
  Root: (props: PropsWithChildren) => (
    <div className="grid grid-cols-2 gap-2" {...props} />
  ),
  Label: (props: PropsWithChildren) => (
    <span className="text-x2s text-neutral-darkest" {...props} />
  ),
  Value: (props: PropsWithChildren) => (
    <span className="text-right text-neutral-dark" {...props} />
  ),
};

function formatDate(dateString: string) {
  return new Intl.DateTimeFormat("pt-BR").format(new Date(dateString));
}

export const AnticipationDetailsItem = ({
  receivable,
  spotAnticipationRequest,
}: AnticipationDetailsItemProps) => {
  const status = useMemo(() => {
    if (receivable.receivableAnticipationSended === null) return "PENDING";
    if (receivable.receivableAnticipationSended === 1) return "PAID";
    if (receivable.receivableAnticipationSended === 0) return "CANCELED";

    return "PENDING";
  }, [receivable]);

  const label: Record<typeof status, string> = {
    CANCELED: "Cancelada",
    PENDING: "Pendente",
    PAID: "Paga",
  };

  return (
    <AccordionItem
      className="space-y-2 rounded-sm border border-neutral-light px-6 py-3 mobile:p-3"
      value={receivable.nsu}
    >
      <AccordionTrigger asChild>
        <div className="flex cursor-pointer items-center justify-between">
          <div className="flex items-center gap-2">
            <div
              className={twMerge(
                "flex h-[40px] w-[40px] items-center justify-center rounded-full",
                status === "CANCELED" &&
                  "bg-status-error-light [&>svg]:fill-status-error-dark",
                status === "PENDING" &&
                  "bg-status-warning-light [&>svg]:fill-status-warning-dark",
                status === "PAID" &&
                  "bg-status-success-light [&>svg]:fill-status-success-dark",
              )}
            >
              <Icon name="MoneyAntecipate" className="size-[22px]" />
            </div>

            <div>
              <h5 className="text-x2s font-medium text-neutral-darkest mobile:text-x4s">
                Venda antecipada
              </h5>

              <p className="text-neutral-dark mobile:text-x2s">
                {receivable.nsu}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex flex-col items-end space-y-2 text-neutral-dark mobile:space-y-0">
              <p className="text-x2s">{label[status]}</p>

              <span
                className={twMerge(
                  status === "CANCELED" && "text-status-error-dark",
                  status === "PENDING" && "text-status-warning-dark",
                  status === "PAID" && "text-status-success-dark",
                )}
              >
                {formatCurrency(receivable.anticipationAmount)}
              </span>
            </div>

            <Icon
              name="ArrowNoLineDown"
              className="size-4 fill-neutral-darkest"
            />
          </div>
        </div>
      </AccordionTrigger>

      <AccordionContent className="space-y-2 overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
        {status === "CANCELED" && (
          <div className="">
            <span className="text-x2s text-neutral-darkest">Motivo</span>
            <p className="text-neutral-dark">
              Cancelado pois não foi encontrado o valor.
            </p>
          </div>
        )}

        {/* <Info.Root>
          <Info.Label>CNPJ</Info.Label>
          <Info.Value>12345.12345/000-1</Info.Value>
        </Info.Root> */}

        <Info.Root>
          <Info.Label>Valor da antecipação</Info.Label>
          <Info.Value>
            {formatCurrency(receivable.installmentAmount)}
          </Info.Value>
        </Info.Root>

        <Info.Root>
          <Info.Label>Desconto de Taxas</Info.Label>
          <Info.Value>
            -
            {formatCurrency(
              receivable.installmentAmount - receivable.paymentAmountToMerchant,
            )}
          </Info.Value>
        </Info.Root>

        <Info.Root>
          <Info.Label>Valor a receber</Info.Label>
          <Info.Value>
            {formatCurrency(receivable.paymentAmountToMerchant)}
          </Info.Value>
        </Info.Root>

        <Info.Root>
          <Info.Label>Data da venda</Info.Label>
          <Info.Value>{formatDate(receivable.createdAt)}</Info.Value>
        </Info.Root>

        <Info.Root>
          <Info.Label>Recebimento previsto em</Info.Label>
          <Info.Value>
            {formatDate(spotAnticipationRequest.paymentDate)}
          </Info.Value>
        </Info.Root>

        {/* <Info.Root>
          <Info.Label>Tipo da Transação</Info.Label>
          <Info.Value>Crédito Parcelado</Info.Value>
        </Info.Root> */}

        {/* <Info.Root>
          <Info.Label>Nº do cartão</Info.Label>
          <Info.Value>****5687</Info.Value>
        </Info.Root> */}

        {/* <Info.Root>
          <Info.Label>Captura</Info.Label>
          <Info.Value>Máquina de Cartão</Info.Value>
        </Info.Root> */}

        {/* <Info.Root>
          <Info.Label>Nº serial do terminal</Info.Label>
          <Info.Value>46499997</Info.Value>
        </Info.Root> */}

        <Info.Root>
          <Info.Label>NSU</Info.Label>
          <Info.Value>{receivable.nsu}</Info.Value>
        </Info.Root>

        {/* <Info.Root>
          <Info.Label>Cod de autorização</Info.Label>
          <Info.Value>1646646499997</Info.Value>
        </Info.Root> */}

        <div className="">
          <span className="text-x2s text-neutral-darkest">Id da Operação</span>
          <p className="text-neutral-dark">{receivable.guidSpotRequests}</p>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
