import { Arrow, Content } from "@radix-ui/react-popover";
import styled, { keyframes } from "styled-components";

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px); }
  100% { opacity: 1; transform: translateX(0); }
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px); }
  100% { opacity: 1; transform: translateX(0); }
`;

export const PopoverContent = styled(Content)`
  padding: ${({ theme: { spacings } }) => spacings.squished.x2s};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.sm};
  background-color: ${({ theme: { palette } }) => palette.neutral.lighter};

  width: auto;

  box-shadow: ${({ theme: { shadows } }) => shadows.full};

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  will-change: transform, opacity;
  outline: none;

  &[data-state="open"] {
    &[data-side="top"] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side="right"] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side="bottom"] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side="left"] {
      animation-name: ${slideRightAndFade};
    }
  }

  &:focus {
    box-shadow: ${({ theme: { shadows } }) => shadows.full};
  }
`;

export const PopoverArrow = styled(Arrow)`
  fill: ${({ theme: { palette } }) => palette.neutral.lighter};
`;
