import { useMemo, useState } from "react";
import { Divider, Skeleton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { formatToBrCurrency } from "../../../receivedValues/utils/formatToBrCurrency";
import { IItem } from "../../api";
import { CalendarItem } from "../../api/useCalendarItem";
import * as S from "./styles";

type CalendarDrawerResumeProps = {
  item: CalendarItem;
  loading?: boolean;
};

type ResumeItemProps = {
  label: string;
  value: number;
  variant?: S.ResumeItemValueProps["variant"];
  loading?: boolean;
};

export const ResumeItem = ({
  label,
  value,
  loading,
  variant,
}: ResumeItemProps) => {
  const formattedValue = useMemo(() => {
    if (loading) {
      return <Skeleton width="32px" height="16px" borderRadius="4px" />;
    }

    return formatToBrCurrency(value);
  }, [loading, value]);

  const valueWithSign = useMemo(() => {
    if (loading) return formattedValue;

    if (variant === "NEGATIVE") {
      return `-R$ ${formattedValue}`;
    }

    return (
      <>
        <S.ResumeCurrencySignal>R$ </S.ResumeCurrencySignal>
        {formattedValue}
      </>
    );
  }, [formattedValue, variant, loading]);

  return (
    <S.ResumeItem>
      <S.ResumeItemLabel>{label}</S.ResumeItemLabel>

      <S.ResumeItemValue variant={variant}>{valueWithSign}</S.ResumeItemValue>
    </S.ResumeItem>
  );
};

const transactionValueMapping = {
  PIX: "Valor a receber Pix",
  Débito: "Valor a receber débito",
  "Crédito a Vista": "Valor a receber crédito(à vista)",
  "Crédito Parcelado": "Valor a receber crédito(parceladas)",
};

const calculateSpecificValues = (items: IItem[]) => {
  const specificValues = {
    "Valor a receber Pix": 0,
    "Valor a receber débito": 0,
    "Valor a receber crédito(à vista)": 0,
    "Valor a receber crédito(parceladas)": 0,
  };

  items.forEach((item) => {
    let transactionType = item.transaction_product;

    if (transactionType === "online")
      transactionType =
        item.total_installments === 1 ? "Crédito a Vista" : "Crédito Parcelado";

    const label = transactionValueMapping[transactionType];

    if (label) {
      specificValues[label] += item.net_value;
    }
  });

  return specificValues;
};

const calculateResumeDetails = (items: IItem[]) => {
  const result = items.reduce(
    (prev, cur) => {
      prev.advanceValue += cur.advance_value;

      prev.discountValue += cur.discount_value;

      prev.grossValue += cur.gross_value;

      prev.netValue += cur.net_value;

      return prev;
    },
    {
      grossValue: 0,
      discountValue: 0,
      advanceValue: 0,
      netValue: 0,
    },
  );

  return result;
};

export const CalendarDrawerResume = ({
  item,
  loading,
}: CalendarDrawerResumeProps) => {
  const [showAllDetails, setShowAllDetails] = useState(false);

  const specificValues = calculateSpecificValues(item?.items || []);

  const { advanceValue, discountValue, grossValue, netValue } =
    calculateResumeDetails(item?.items || []);

  return (
    <S.Container>
      <S.Header>
        <S.HeaderContent>
          <S.HeaderIcon>
            <Icon name="GeralMoneyMoney" width={24} height={24} />
          </S.HeaderIcon>

          <S.HeaderTexts>
            <S.HeaderTitle>Valor bruto</S.HeaderTitle>

            <S.HeaderSubtitle>
              {loading ? (
                <Skeleton width="24px" height="14px" borderRadius="4px" />
              ) : (
                item?.items.length
              )}{" "}
              vendas
            </S.HeaderSubtitle>
          </S.HeaderTexts>
        </S.HeaderContent>

        {loading ? (
          <Skeleton width="64px" height="24px" borderRadius="4px" />
        ) : (
          <S.GrossValue>
            <S.GrossValueCurrency>R$ </S.GrossValueCurrency>
            {formatToBrCurrency(grossValue)}
          </S.GrossValue>
        )}
      </S.Header>

      <Divider />

      <S.Resume>
        {showAllDetails && (
          <>
            {Object.keys(specificValues).map((key) => {
              const value = specificValues[key];

              const variant = (): S.ResumeItemValueProps["variant"] => {
                if (value < 0) return "NEGATIVE";

                return undefined;
              };

              return (
                <ResumeItem
                  label={key}
                  value={value}
                  variant={variant()}
                  loading={loading}
                  key={key}
                />
              );
            })}
          </>
        )}

        <ResumeItem
          label="Desconto de taxas"
          value={discountValue}
          variant="NEGATIVE"
          loading={loading}
        />

        <ResumeItem
          label="Desconto de antecipação"
          value={advanceValue}
          variant="NEGATIVE"
          loading={loading}
        />

        <ResumeItem
          label="Valor total a receber"
          value={netValue}
          variant="POSITIVE"
          loading={loading}
        />

        <S.ResumeDetails
          onClick={() =>
            setShowAllDetails((prevShowDetails) => !prevShowDetails)
          }
          showAllDetails={showAllDetails}
        >
          <Icon name="ArrowArrowNoLineUp" width={16} height={16} />
          {showAllDetails ? "Ver menos detalhes" : "Ver mais detalhes"}
        </S.ResumeDetails>
      </S.Resume>
    </S.Container>
  );
};
