import { useRef } from "react";
import { Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { Card, IModalProps } from "../../../../components";
import { useDisclosure } from "../../../../hooks";
import { formatTotalSalesToView, IItem } from "../../../../utils";
import { Disclosure } from "../Disclosure";
import * as Styles from "./Details.styles";

type IDetailsModalProps = Partial<IModalProps> & {
  content: {
    items: IItem[];
    date: string;
    total: number;
  };
};

export function DetailsModal({ isOpen, onClose, content }: IDetailsModalProps) {
  const { disclosures, expandAll, toggleDisclosures } = useDisclosure();

  const drawerTopRef = useRef(null);

  const handleBackToTop = () => {
    if (!drawerTopRef.current) {
      return null;
    }

    return drawerTopRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = () => {
    expandAll([]);
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      onDismiss={handleClose}
      title={`Recebidos de ${content?.date}`}
      position="right"
    >
      <div ref={drawerTopRef} />

      <Card
        avatar={{ icon: "MoneyDollarCircle" }}
        title="Total de vendas no dia"
        subTitle={formatTotalSalesToView(content?.items?.length)}
        value={content?.total}
        width={432}
        minWidth={432}
        height={84}
        padding="0px"
      />

      <Styles.Content>
        <Styles.ExpandAllButton
          className="expandAllButtton"
          onClick={() => expandAll(content?.items)}
        >
          <p>Expandir todos</p>
          <div>
            <Icon
              name="ArrowArrowNoLineRight"
              data-expanded={disclosures.length === content?.items?.length}
            />
          </div>
        </Styles.ExpandAllButton>
        <Styles.ListContainer>
          <Styles.List>
            <div className="listWrapper">
              {content?.items?.map((item, index) => {
                const expanded = disclosures.includes(index.toString());

                return (
                  <Disclosure
                    key={item.guid_onlead_item}
                    item={item}
                    expanded={expanded}
                    handleOpenDisclosure={() =>
                      toggleDisclosures(index.toString())
                    }
                  />
                );
              })}
            </div>

            {(disclosures.length > 1 || content?.items?.length > 5) && (
              <Styles.GoToTopButton
                className="button"
                icon="ArrowArrowNoLineUp"
                variant="secondary"
                onClick={handleBackToTop}
              >
                TOPO
              </Styles.GoToTopButton>
            )}
          </Styles.List>
        </Styles.ListContainer>
      </Styles.Content>
    </Drawer>
  );
}
