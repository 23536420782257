import { Skeleton } from "@hyperlocal/vital";
import * as S from "./styles";

export const CalendarTableSkeleton = () => {
  return (
    <S.TableContainer className="calendar-table">
      <S.Table>
        <thead>
          <tr>
            <S.Th>Dias</S.Th>
            <S.Th>Recebimentos</S.Th>
            <S.Th>Valores do Dia</S.Th>
            <S.Th></S.Th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 30 }, (_, index) => (
            <tr key={index}>
              <S.Td>
                <Skeleton width="40px" height="40px" borderRadius="50%" />
              </S.Td>
              <S.Td>
                <Skeleton width="32px" height="16px" borderRadius="4px" />
              </S.Td>
              <S.Td>
                <Skeleton width="32px" height="16px" borderRadius="4px" />
              </S.Td>
              <S.Td>
                <Skeleton width="24px" height="24px" borderRadius="4px" />
              </S.Td>
            </tr>
          ))}
        </tbody>
      </S.Table>
    </S.TableContainer>
  );
};
