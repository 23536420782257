import { useState } from "react";
import {
  formatCurrency,
  getReceivedRoutesData,
  Hooks,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Sheet } from "@hyperlocal/vital2";
import { Link, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { AnticipationRequest } from "../../api";
import { AnticipationDetails } from "./details";

type AnticipationListCardProps = {
  anticipation: AnticipationRequest;
  anticipationTotalAmount: number;
  anticipationTotalCount: number;
};

export const AnticipationListCard = ({
  anticipation,
  anticipationTotalCount,
}: AnticipationListCardProps) => {
  const [open, setOpen] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const isMobile = Hooks.useMediaQuery("mobile");
  const routes = getReceivedRoutesData();

  const title: Record<AnticipationRequest["status"], string> = {
    APPROVED: "Antecipação aprovada",
    PAID: "Antecipação paga",
    PENDING: "Antecipação pendente",
    REJECTED: "Antecipação rejeitada",
  };

  const setGuidInSearchParams = () => {
    setSearchParams((prevParams) => {
      prevParams.set("guid", anticipation.guidSpotRequests);
      return prevParams;
    });
  };

  const removeGuidFromSearchParams = () => {
    setSearchParams((prevParams) => {
      prevParams.delete("guid");
      return prevParams;
    });
  };

  const card = (
    <div className="flex cursor-pointer items-center justify-between rounded-sm border border-neutral-light p-3 desktop:px-6 desktop:py-3">
      <div className="flex items-center gap-2">
        <div
          className={twMerge(
            "flex h-[40px] w-[40px] items-center justify-center rounded-full",
            anticipation.status === "REJECTED" &&
              "bg-status-error-light [&>svg]:fill-status-error-dark",
            anticipation.status === "PENDING" &&
              "bg-status-warning-light [&>svg]:fill-status-warning-dark",
            anticipation.status === "PAID" &&
              "bg-status-success-light [&>svg]:fill-status-success-dark",
          )}
        >
          <Icon
            name="MoneyAntecipate"
            className={twMerge("size-[22px] fill-status-warning-dark")}
          />
        </div>

        <div>
          <h5 className="text-x4s font-medium text-neutral-darkest desktop:text-x2s">
            {title[anticipation.status]}
          </h5>

          <p className="text-x2s text-neutral-dark desktop:text-xs">
            {anticipation.receivablesCountRequested} vendas
          </p>
        </div>
      </div>

      <div className=":space-y-0 flex flex-col items-end text-neutral-dark desktop:space-y-2">
        <p className="text-x2s">Total de vendas</p>

        <span className={twMerge("text-status-warning-dark")}>
          {formatCurrency(anticipation.prepaymentGrossAmountRequested)}
        </span>
      </div>
    </div>
  );

  if (isMobile) {
    return <Link to={routes.futureReceiptsAnticipationDetails}>{card}</Link>;
  }

  return (
    <Sheet.Root
      onOpenChange={(open) => {
        if (open) {
          setGuidInSearchParams();
          return setOpen(open);
        }

        removeGuidFromSearchParams();
        setOpen(open);
      }}
      open={open}
    >
      <Sheet.Trigger asChild>{card}</Sheet.Trigger>

      <Sheet.Content className="[&>[data-role=close-sheet]]:hidden">
        <Sheet.Header className="flex w-full flex-row items-center justify-between">
          <div className="text-md font-bold text-neutral-darkest">
            {anticipationTotalCount} vendas
          </div>

          <button
            onClick={() => {
              removeGuidFromSearchParams();
              setOpen(false);
            }}
          >
            <Icon
              name="SettingTrashClose"
              fill="currentColor"
              className="size-inset-lg"
            />
          </button>
        </Sheet.Header>

        <AnticipationDetails />
      </Sheet.Content>
    </Sheet.Root>
  );
};

export const AnticipationListCardSkeleton = () => {
  return (
    <div className="flex h-[80px] cursor-pointer items-center justify-between rounded-sm border border-neutral-light p-3 desktop:px-6 desktop:py-3">
      <div className="flex items-center gap-2">
        <div className="size-10 animate-pulse rounded-full bg-neutral-lighter" />

        <div className="space-y-2">
          <div className="h-[2ex] w-[20ch] animate-pulse rounded-xs bg-neutral-lighter" />

          <div className="h-[1.5ex] w-[15ch] animate-pulse rounded-xs bg-neutral-lighter" />
        </div>
      </div>

      <div className="flex flex-col items-end space-y-2 text-neutral-dark">
        <div className="h-[1.5ex] w-[10ch] animate-pulse rounded-xs bg-neutral-lighter" />

        <div className="h-[2ex] w-[5ch] animate-pulse rounded-xs bg-neutral-lighter" />
      </div>
    </div>
  );
};
