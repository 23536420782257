import { Skeleton } from "@hyperlocal/vital";
import { useDisclosure } from "../../../../hooks";
import { capitalizeFirstLetter } from "../../../../utils";
import { adjustForTimezone } from "../../../../utils/date/adjustForTimezone";
import { formatToUI } from "../../../../utils/date/formatToUI";
import { formatToWeekDay } from "../../../../utils/date/formatToWeekDay";
import { Disclosure } from "../../../receivedValues/components/Disclosure";
import { Item } from "../../api/useCalendarItem";
import * as S from "./styles";

type CalendarDrawerListProps = {
  items?: Item[];
  loading: boolean;
  date: Date;
};

export const CalendarDrawerListSkeleton = () => {
  return (
    <>
      <S.Header>
        <S.Date>
          <Skeleton width="107px" height="30px" borderRadius="4px" />
        </S.Date>

        <Skeleton width="91px" height="20px" borderRadius="4px" />
      </S.Header>

      <S.SkeletonList>
        {Array.from({ length: 5 })?.map((_, index) => {
          return (
            <Skeleton
              width="100%"
              height="73px"
              borderRadius="8px"
              key={index}
            />
          );
        })}
      </S.SkeletonList>
    </>
  );
};

export const CalendarDrawerList = ({
  items,
  date,
}: CalendarDrawerListProps) => {
  const { disclosures, toggleDisclosures } = useDisclosure();

  const adjustedDate = adjustForTimezone(new Date(date));

  return (
    <>
      <S.Header>
        <S.Date>{formatToUI(adjustedDate)}</S.Date>
        <S.Weekday>
          {capitalizeFirstLetter(formatToWeekDay(adjustedDate))}
        </S.Weekday>
      </S.Header>

      <ul>
        {items?.map((item, index) => {
          const id = index.toString();
          const expanded = disclosures.includes(id);

          return (
            <Disclosure
              key={item.guid_onlead_item}
              item={item}
              expanded={expanded}
              handleOpenDisclosure={() => toggleDisclosures(id)}
            />
          );
        })}
      </ul>
    </>
  );
};
