import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export type CardType = "NET" | "GROSS";

export const Card = styled.div(
  ({ theme: { palette, spacings, borderRadius } }) => ({
    background: palette.neutral.white,
    width: "100%",

    padding: spacings.squished.lg,
    borderRadius: borderRadius.sm,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [EBreakPoints.mobile]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: spacings.stack.nano,

      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: palette.neutral.light,

      padding: spacings.inset.md,
    },
  }),
);

export const CardContent = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  alignItems: "center",
  gap: spacings.inline.x2s,
}));

type CardIconContainerProps = {
  type: CardType;
};

export const CardIconContainer = styled.div<CardIconContainerProps>(
  ({ theme: { palette, borderRadius, spacings }, type }) => {
    const background: Record<CardType, string> = {
      GROSS: palette.primary.lighter,
      NET: palette.secondary.lighter,
    };

    const color: Record<CardType, string> = {
      GROSS: palette.primary.main,
      NET: palette.secondary.darker,
    };

    return {
      background: background[type],
      borderRadius: borderRadius.circle,
      padding: spacings.inset.x2s,

      width: "32px",
      height: "32px",

      svg: {
        fill: color[type],
      },
    };
  },
);

export const CardAmount = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacings.stack.quarck,
}));

export const CardLabel = styled.p(
  ({ theme: { palette, fontWeights, fontSizes } }) => ({
    color: palette.neutral.darkest,
    fontWeight: fontWeights.medium,
    whiteSpace: "nowrap",

    [EBreakPoints.mobile]: {
      fontSize: fontSizes.x3s,
    },
  }),
);

export const CardAmountValue = styled.span(
  ({ theme: { palette, fontSizes } }) => ({
    color: palette.neutral.dark,
    fontSize: fontSizes.x2s,

    display: "flex",
    alignItems: "center",
    gap: "5px",

    [EBreakPoints.mobile]: {
      fontSize: fontSizes.x4s,
    },
  }),
);

type CardValueProps = {
  type: CardType;
};

export const CardValue = styled.span<CardValueProps>(
  ({ theme: { palette, fontSizes, fontWeights }, type }) => {
    const color: Record<CardType, string> = {
      GROSS: palette.primary.main,
      NET: palette.secondary.darker,
    };

    return {
      color: color[type],
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.bold,
    };
  },
);
