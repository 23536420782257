import { useMemo } from "react";
import { Button } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { Switch } from "@hyperlocal/vital2";
import { addMonths, format, isSameMonth, subMonths } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Popover } from "../../../../components/Popover";
import { capitalizeFirstLetter } from "../../../../utils";
import * as S from "./styles";

type HandleDateFn = (date: Date) => void;

export type SelectionMode = "SINGLE" | "RANGE";

type CalendarHeaderProps = {
  date: Date;
  handlePrevMonth: HandleDateFn;
  handleNextMonth: HandleDateFn;
  handleCurrentMonth: HandleDateFn;

  selectionMode: SelectionMode;
  handleSelectionModeChange: (isRangeMode: boolean) => void;
};

export const CalendarHeader = ({
  date,
  handleNextMonth,
  handlePrevMonth,
  handleCurrentMonth,
  selectionMode,
  handleSelectionModeChange,
}: CalendarHeaderProps) => {
  const formattedMonth = useMemo(() => {
    const month = format(date, "MMMM", {
      locale: ptBR,
    });

    return capitalizeFirstLetter(month);
  }, [date]);

  const nextMonth = () => {
    handleNextMonth(addMonths(date, 1));
  };

  const prevMonth = () => {
    handlePrevMonth(subMonths(date, 1));
  };

  const currentMonth = () => {
    handleCurrentMonth(new Date());
  };

  const isCurrentMonth = isSameMonth(date, new Date());

  return (
    <S.CalendarHeader>
      <S.CalendarHeaderMonthControllers>
        <S.CalendarHeaderTitle>Meus recebimentos</S.CalendarHeaderTitle>

        <S.CalendarHeaderMonth>
          <S.CalendarHeaderArrowButton onClick={prevMonth}>
            <S.CalendarHeaderArrow name="ArrowArrowNoLineLeft" />
          </S.CalendarHeaderArrowButton>

          <S.CalendarHeaderMonthValue>
            {formattedMonth}
          </S.CalendarHeaderMonthValue>

          <S.CalendarHeaderArrowButton onClick={nextMonth}>
            <S.CalendarHeaderArrow name="ArrowArrowNoLineRight" />
          </S.CalendarHeaderArrowButton>
        </S.CalendarHeaderMonth>

        <Button variant="link" onClick={currentMonth} disabled={isCurrentMonth}>
          Ver mês atual
        </Button>
      </S.CalendarHeaderMonthControllers>

      <S.SwitchContainer>
        <Switch
          onCheckedChange={handleSelectionModeChange}
          checked={selectionMode === "RANGE"}
          id="intervalSelect"
        />
        <label htmlFor="intervalSelect" className="cursor-pointer select-none">
          Selecionar intervalo
        </label>
        <Popover
          content={
            <S.SwitchPopoverContent>
              Escolha 2 dias para definir
              <br /> o intervalo de tempo.
            </S.SwitchPopoverContent>
          }
          side="top"
        >
          <S.SwitchPopoverTrigger>
            <Icon name="TagsAlertsInfoCircle" width={16} height={16} />
          </S.SwitchPopoverTrigger>
        </Popover>
      </S.SwitchContainer>
    </S.CalendarHeader>
  );
};
