import { GetAnticipationParams } from "@/features/futureReceipts/api";

export const anticipationKeys = {
  default: ["anticipation"],
  receivables: () => [...anticipationKeys.default, "receivables"],
  getAnticipation: (params: GetAnticipationParams) => [
    ...anticipationKeys.default,
    "anticipation",
    { ...params },
  ],
  getAnticipationDetail: (id: string) => [
    ...anticipationKeys.default,
    "anticipationDetail",
    id,
  ],
  getCalendarItem: ({
    guidAccount,
    startDate,
    endDate,
  }: {
    guidAccount: string;
    startDate: string;
    endDate: string;
  }) => [
    ...anticipationKeys.default,
    "calendarItem",
    guidAccount,
    startDate,
    endDate,
  ],
};
