import { useState } from "react";
import { Hooks } from "@/utils";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card, Dialog, Sheet } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { useDownloadDocument } from "../../api";

interface IExportFilesModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  data: T;
  onSuccess?: () => void;
}

type IExcelExtension = {
  name: "Excel";
  extension: "xlsx";
};

type IPDFExtension = {
  name: "PDF";
  extension: "pdf";
};

type IExtension = IExcelExtension | IPDFExtension;

const EXTENSIONS: Array<IExtension> = [
  {
    name: "Excel",
    extension: "xlsx",
  },
  {
    name: "PDF",
    extension: "pdf",
  },
];

const ICONS: Record<PropertyKey, IconProps["name"]> = {
  Excel: "GeralDocApkDocument",
  PDF: "GeralDocPdf",
};

function ExportContainer<T>({
  data,
  fileName,
  onSuccess,
}: Pick<IExportFilesModalProps<T>, "data" | "fileName" | "onSuccess">) {
  const [selectedFile, setSelectedFile] = useState<IExtension["name"]>("Excel");
  const { mutateAsync, isLoading } = useDownloadDocument();
  async function handleExport(extension: IExtension["extension"]) {
    await mutateAsync(
      {
        extension,
        body: data,
        name: fileName,
      },
      {
        onSuccess,
      },
    );
  }

  return (
    <div>
      Por favor, selecione uma das opções sobre esta compra:
      <div className="mt-2 flex flex-col gap-2">
        {EXTENSIONS.map((item) => (
          <button
            key={item.extension}
            onClick={async () => {
              setSelectedFile(item.name);
              await handleExport(item.extension);
            }}
            disabled={isLoading}
            className={twMerge(isLoading && "cursor-not-allowed")}
          >
            <Card
              className={twMerge(
                "flex w-full cursor-pointer content-between items-center text-neutral-black",
                isLoading && "cursor-not-allowed",
              )}
            >
              <div className="flex w-full gap-inset-x2s">
                <Icon
                  name={ICONS[item.name]}
                  fill="currentColor"
                  className="size-inset-lg"
                />
                <span>{item.name}</span>
              </div>
              {isLoading && selectedFile === item.name ? (
                <Icon
                  name="ArrowRefreshLine"
                  fill="currentColor"
                  className="size-inset-lg animate-spin"
                />
              ) : (
                <Icon
                  name="ArrowNoLineRigh"
                  fill="currentColor"
                  className="size-inset-lg"
                />
              )}
            </Card>
          </button>
        ))}
      </div>
    </div>
  );
}

export function ExportFilesDrawer<T>({
  isOpen,
  onClose,
  ...rest
}: IExportFilesModalProps<T>) {
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <>
      {isMobile && (
        <Sheet.Root open={isOpen} onOpenChange={onClose}>
          <Sheet.Content side="bottom">
            <Sheet.Header>
              <Sheet.Title>Exportar</Sheet.Title>
            </Sheet.Header>
            <ExportContainer {...rest} />
          </Sheet.Content>
        </Sheet.Root>
      )}
      {!isMobile && (
        <Dialog.Root open={isOpen} onOpenChange={onClose}>
          <Dialog.Content className="max-w-[372px]">
            <Dialog.Header>
              <Dialog.Title>Exportar</Dialog.Title>
            </Dialog.Header>
            <ExportContainer {...rest} />
          </Dialog.Content>
        </Dialog.Root>
      )}
    </>
  );
}
