import styled from "styled-components";

export const CalendarResume = styled.div(
  ({
    theme: {
      spacings: { inline },
    },
  }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: inline.xs,
  }),
);
