import { IconProps } from "@hyperlocal/vital-icons";

export const parseCardBrandIcon = (brand: string): IconProps["name"] => {
  const brandName = brand.toUpperCase();

  const brandIcons = {
    MASTERCARD: "CompaniesCompaniesCardMasterColored",
    VISA: "CompaniesCompaniesCardVisaColored",
    "VISA ELECTRON": "CompaniesCompaniesCardVisaColored",
    "ELO CREDITO": "CompaniesCompaniesCardEloColored",
    "ELO DEBITO": "CompaniesCompaniesCardEloColored",
    ELO: "CompaniesCompaniesCardEloColored",
    AMEX: "CompaniesCompaniesCardAmericanExpressColored",
    "AMEX CREDITO": "CompaniesCompaniesCardAmericanExpressColored",
    "CABAL CREDITO": "CompaniesCompaniesCardCabalColored",
    HIPERCARD: "CompaniesCompaniesCardHipercardColored",
    MAESTRO: "CompaniesCompaniesCardMaestroColored",
    HIPER: "CompaniesCompaniesCardHiperColored",
    SOROCRED: "CompaniesCompaniesCardSorocredColored",
    DINERSCLUB: "CompaniesCompaniesCardDinersColored",
    DISCOVER: "CompaniesCompaniesCardDiscoverColored",
    JCB: "CompaniesCompaniesCardJcbColored",
    AURA: "CompaniesCompaniesCardAuraColored",
    CREDZ: "CompaniesCompaniesCardCredzColored",
    CREDSYSTEM: "CompaniesCompaniesCardCredsystemColored",
    BANESCARD: "CompaniesCompaniesCardBanescardColored",
    WOW: "CompaniesCompaniesCardWowColored",
    PIX: "MoneyPix",
    "": "GeralTaxa",
  };

  return brandIcons[brandName] || "MoneyPix";
};
