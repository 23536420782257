export function formatTotalSalesToView(totalSalesPerPeriod: number): string {
  if (totalSalesPerPeriod === 1) {
    return `0${totalSalesPerPeriod} venda`;
  }
  if (totalSalesPerPeriod >= 2 && totalSalesPerPeriod <= 9) {
    return `0${totalSalesPerPeriod} vendas`;
  }

  return `${totalSalesPerPeriod ?? 0} vendas`;
}
