import { getFirstAndLastDayOfMonth } from "@/utils";
import { formatToAPI } from "../../../../utils/date/formatToAPI";
import { CalendarItem, useCalendarItem } from "../../api/useCalendarItem";
import { CalendarCard } from "../CalendarCard";
import * as S from "./styles";

type CalendarResumeProps = {
  selectedDate: Date;
  isLoadingCalendarItems: boolean;
};

function getMonthValuesResume(data?: CalendarItem) {
  const result = data?.items.reduce(
    (prev, cur) => {
      prev.grossValue += cur.gross_value;

      prev.netValue += cur.net_value;

      return prev;
    },
    {
      netValue: 0,
      grossValue: 0,
    },
  );

  return result;
}

export const CalendarResume = ({
  selectedDate,
  isLoadingCalendarItems,
}: CalendarResumeProps) => {
  const { firstDay, lastDay } = getFirstAndLastDayOfMonth(selectedDate);

  const { data, isLoading } = useCalendarItem({
    status: "SCHEDULED",
    startDate: formatToAPI(firstDay),
    endDate: formatToAPI(lastDay),
  });

  const values = getMonthValuesResume(data);

  return (
    <S.CalendarResume>
      <CalendarCard.Root>
        <CalendarCard.Content>
          <CalendarCard.Icon type="GROSS" />

          <CalendarCard.Amount
            amount={data?.items.length}
            type="GROSS"
            loading={isLoading || isLoadingCalendarItems}
          />
        </CalendarCard.Content>
        <CalendarCard.Value
          value={values?.grossValue}
          type="GROSS"
          loading={isLoading || isLoadingCalendarItems}
        />
      </CalendarCard.Root>

      <CalendarCard.Root>
        <CalendarCard.Content>
          <CalendarCard.Icon type="NET" />

          <CalendarCard.Amount
            amount={data?.items.length}
            type="NET"
            loading={isLoading || isLoadingCalendarItems}
          />
        </CalendarCard.Content>

        <CalendarCard.Value
          value={values?.netValue}
          type="NET"
          loading={isLoading || isLoadingCalendarItems}
        />
      </CalendarCard.Root>
    </S.CalendarResume>
  );
};
