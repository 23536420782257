import { IModalProps, Modal } from "../../../../components";

type IConfirmAdvanceModalProps = Partial<IModalProps>;

export function ConfirmAdvanceModal({
  isOpen,
  onClose,
}: IConfirmAdvanceModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ width: 480, height: 1024 }}
    >
      <Modal.Header size="md" onClose={onClose}>
        Confirmar Antecipacão
      </Modal.Header>

      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 59,
            width: "100%",
            padding: 32,
          }}
        >
          <p>Período</p>
          <p>01/04/2022 a 22/12/2022</p>
        </div>
        <div>
          <p>Vendas</p>
          <p>
            Crédito à vista <span>(100 vendas)</span>
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Button variant="primary">Confirmar</Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}
