import { Container } from "./Spinner.styles";

export function Spinner() {
  return (
    <Container role="progressbar" viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
      />
    </Container>
  );
}
