import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
`;

export const Th = styled.th(
  ({ theme: { palette, fontSizes, fontWeights } }) => ({
    background: "transparent",
    color: palette.neutral.darker,
    fontSize: fontSizes.x2s,
    textAlign: "left",
    fontWeight: fontWeights.medium,
  }),
);

type DayProps = {
  isCurrent: boolean;
};

export const Day = styled.div<DayProps>(
  ({
    theme: { palette, fontSizes, borderRadius, fontWeights },
    isCurrent,
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    fontSize: fontSizes.x3s,
    fontWeight: fontWeights.medium,

    color: isCurrent ? palette.neutral.white : palette.neutral.dark,
    background: isCurrent ? palette.primary.main : "transparent",
    borderRadius: isCurrent ? borderRadius.circle : borderRadius.none,

    width: "40px",
    height: "40px",
  }),
);

export const Td = styled.td(({ theme: { palette, spacings } }) => ({
  paddingTop: spacings.stack.nano,
  paddingBottom: spacings.stack.nano,

  svg: {
    fill: palette.neutral.darkest,
  },
}));

export const TdValue = styled.span(
  ({ theme: { palette, fontSizes, fontWeights } }) => ({
    color: palette.primary.main,
    fontSize: fontSizes.x2s,
    fontWeight: fontWeights.medium,
  }),
);

type TrProps = {
  isSelected: boolean;
  isDisabled: boolean;
};

export const Tr = styled.tr<TrProps>(
  ({ theme: { palette, borderRadius, spacings }, isSelected, isDisabled }) => {
    if (isDisabled) {
      return {
        pointerEvents: "none",
        cursor: "not-allowed",

        [TdValue]: {
          color: palette.neutral.dark,
        },

        td: {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: palette.neutral.light,

          background: palette.neutral.lighter,
        },

        "td:first-child": {
          borderTopLeftRadius: borderRadius.xs,
        },

        "td:last-child": {
          borderTopRightRadius: borderRadius.xs,
        },
      };
    }

    if (isSelected) {
      return {
        td: {
          borderColor: palette.primary.main,
          borderWidth: "2px",
          borderStyle: "solid",
        },

        "td:nth-child(2), td:nth-child(3)": {
          borderRightWidth: "0px",
          borderLeftWidth: "0px",
        },

        "td:first-child": {
          borderRightWidth: "0px",

          borderTopLeftRadius: borderRadius.xs,
          borderBottomLeftRadius: borderRadius.xs,
        },

        "td:last-child": {
          borderLeftWidth: "0px",

          borderTopRightRadius: borderRadius.xs,
          borderBottomRightRadius: borderRadius.xs,
        },

        color: palette.primary.main,
        padding: spacings.inset.x2s,
      };
    }

    return {
      padding: spacings.inset.x2s,

      td: {
        borderColor: "transparent",
        borderWidth: "2px",
        borderStyle: "solid",
      },

      "td:nth-child(2), td:nth-child(3)": {
        borderRightWidth: "0px",
        borderLeftWidth: "0px",
      },

      [Td]: {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: palette.neutral.light,
      },
    };
  },
);
