import {
  Button as VitalButton,
  Divider as VitalDivider,
} from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import styled, { css } from "styled-components";
import { EBreakPoints } from "../../@types";
import { IContainerProps, IHeaderProps } from "./types";

const fadeIn = css`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div((props) => ({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(23, 23, 23, 0.8)",
  display: "flex",

  "&[data-state='closed']": {
    visibility: "hidden",
  },
  "&[data-state='open']": {
    visibility: "visible",
  },

  justifyContent: "center",
  alignItems: "center",
  padding: props.theme.spacings.stack.sm,

  zIndex: 1000,

  overflow: "hidden",

  "@media (prefers-reduced-motion: no-preference)": {
    animationFillMode: "backwards",
    animationName: `${fadeIn} 1s linear infinite`,
    animationDuration: "400ms",
    animationDelay: "0",
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    padding: 0,
  },
}));

export const Container = styled.div<IContainerProps>((props) => ({
  "&[data-state='closed']": {
    visibility: "hidden",
  },
  "&[data-state='open']": {
    visibility: "visible",
  },
  width: props.size === "full" ? "100vw" : props.size.width,
  height: props.size === "full" ? "100vh" : props.size.height,
  minHeight: 281,
  overflow: "auto",
  // position: props.size === "full" ? "absolute" : "relative",
  top: 0,
  left: 0,
  position: "relative",
  // top: 0,
  // left: 0,

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: props.theme.spacings.inset.md,
  gap: props.theme.spacings.stack.nano,

  // width: 343,
  // height: 281,

  backgroundColor: props.theme.palette.neutral.white,
  boxShadow: "0 0 4px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,
  [EBreakPoints.mobile]: {
    width: "100vw",
    height: "100vh",
  },
}));

export const Header = styled.header<IHeaderProps>((props) => {
  const sizes = {
    sm: {
      padding: 0,
      gap: props.theme.spacings.inset.x2s,
      alignItems: "flex-start",
      "& h6": {
        paddingBottom: props.theme.spacings.inline.x2s,
        fontSize: props.theme.fontSizes.xs,
        fontWeight: props.theme.fontWeights.bold,
        lineHeight: props.theme.lineHeights.md,
        fontFamily: props.theme.fonts.base,
        color: props.theme.palette.primary.dark,
      },
    },
    md: {
      padding: props.theme.spacings.inline.md,
      gap: props.theme.spacings.inset.md,
      alignItems: "center",
      "& h6": {
        paddingBottom: props.theme.spacings.inline.x2s,
        fontSize: props.theme.fontSizes.sm,
        fontWeight: props.theme.fontWeights.bold,
        lineHeight: props.theme.lineHeights.md,
        fontFamily: props.theme.fonts.base,
        color: props.theme.palette.primary.dark,
      },
    },
    lg: {},
  };

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",

    width: "100%",
    height: 32,
    ...sizes[props.size],
    boxShadow: props.theme.shadows.lower,

    [EBreakPoints.mobile]: {
      maxHeight: 32,
      width: "100%",
      height: "100%",
    },
  };
});

export const CloseButton = styled.button((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  width: 24,
  height: 24,

  background: "transparent",
  border: "none",
  outline: "none",
  cursor: "pointer",

  "&:hover": {
    background: props.theme.palette.neutral.light,
    borderRadius: 4,
  },
}));

export const CloseIcon = styled(Icon)({
  width: 16,
  height: 16,

  "& path": {
    fill: "#000",
    stroke: "#000",
  },
});

export const Title = styled.h6((props) => ({
  fontSize: props.theme.fontSizes.xs,
  fontWeight: props.theme.fontWeights.bold,
  lineHeight: props.theme.lineHeights.md,
  fontFamily: props.theme.fonts.base,
  color: props.theme.palette.primary.dark,
}));

export const Divider = styled(VitalDivider)((props) => ({
  width: 528,
  height: 1,

  background: props.theme.palette.neutral.main,
  opacity: 0.16,
}));

export const Subtitle = styled.p((props) => ({
  fontSize: props.theme.fontSizes.xs,
  fontWeight: 400,
  lineHeight: props.theme.lineHeights.sm,
  fontFamily: props.theme.fonts.base,
  color: props.theme.palette.neutral.darkest,
}));

export const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  padding: 0,
  gap: props.theme.spacings.inset.md,

  // width: "100%",
  // height: "100%",

  p: {
    fontSize: props.theme.fontSizes.xs,
    fontWeight: props.theme.fontWeights.regular,
    lineHeight: props.theme.lineHeights.md,
  },
}));

export const Footer = styled.footer((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: props.theme.spacings.stack.nano,

  width: "auto",
  height: "100%",

  [EBreakPoints.mobile]: {
    width: "100%",
    height: "auto",

    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
}));

export const Button = styled(VitalButton)((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  padding: `${props.theme.spacings.inline.xs} ${props.theme.spacings.inline.sm}`,
  gap: props.theme.spacings.inline.xs,

  width: 311,
  height: 48,
}));
