import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IUseDisclosureProps<T extends Record<string, any>> {
  isOpen: boolean;
  open: (props?: T) => void;
  close: () => void;
  toggle: () => void;
  toggleDisclosures: (id: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandAll: <D extends Record<string, any>>(items: D[]) => void;
  disclosures: string[];
  modalProps?: T;
}

export function useDisclosure<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
>(): IUseDisclosureProps<T> {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<T>({} as T);
  const [disclosures, setDisclosures] = useState<string[]>([]);

  const open = useCallback((props?: T) => {
    setIsOpen(true);
    setModalProps(props || ({} as T));
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setModalProps({} as T);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    setModalProps({} as T);
  }, [isOpen]);

  const toggleDisclosures = useCallback(
    (i: string) => {
      const index = disclosures.indexOf(i);
      return index !== -1
        ? setDisclosures([
            ...disclosures.slice(0, index),
            ...disclosures.slice(index + 1),
          ])
        : setDisclosures([...disclosures, i]);
    },
    [disclosures],
  );

  const expandAll = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <D extends Record<string, any>>(items: D[]) => {
      if (disclosures.length === 0) {
        return items.forEach((item, index) => {
          setDisclosures((prev) => [...new Set([...prev, index.toString()])]);
        });
      }
      return setDisclosures([]);
    },
    [disclosures.length],
  );

  return {
    isOpen,
    open,
    close,
    toggle,
    modalProps,
    toggleDisclosures,
    expandAll,
    disclosures,
  };
}
