import { ReactNode } from "react";
import { PopoverContentProps, Root, Trigger } from "@radix-ui/react-popover";
import * as S from "./styles";

type PopoverProps = {
  children: ReactNode;
  content: ReactNode;
  side?: PopoverContentProps["side"];
};

export const Popover = ({ children, content, side }: PopoverProps) => (
  <Root>
    <Trigger asChild>{children}</Trigger>

    <S.PopoverContent sideOffset={5} side={side}>
      {content}

      <S.PopoverArrow />
    </S.PopoverContent>
  </Root>
);
