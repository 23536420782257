export function isValidDate(date) {
  return date instanceof Date && Number.isFinite(date.getTime());
}

export function getFirstAndLastDayOfMonth(date: Date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { firstDay, lastDay };
}
