// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toast, useAccountStore } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../lib/axios";
import { IQueryConfig } from "../../../lib/react-query";
import { useFilters } from "../stores";

export interface IItem {
  guid_onlead_item: string | null;
  transaction_date: string;
  processing_date: string;
  status: string;
  gross_value: number;
  discount_value: number;
  advance_value: number;
  net_value: number;
  brand_description: string;
  transaction_product: string;
  installment_number: number;
  total_installments: number;
  nsu: string | null;
  terminal_serial_number: string;
  type: string;
  bank_address: null;
}

export interface ISalesReceiptItem {
  advance_value: number;
  discount_value: number;
  gross_value: number;
  net_value: number;
  items: IItem[];
}

export type ISalesListParams = {
  guidAccount?: string;
  startDate: string;
  endDate?: string;
  status?: string;
  pageParam?: number;
};

export async function getSalesList({
  guidAccount,
  startDate,
  endDate,
  status,
}: ISalesListParams) {
  try {
    const response = await api.get<ISalesReceiptItem>(
      `/Sales/${guidAccount}/Receipts/Items/Date`,
      {
        timeout: 600000,
        params: {
          startDate,
          endDate,
          status,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log("🚀 ~ file: getSales.ts:82 ~ error:", error);
    toast.error("Houve um problema ao buscar os dados.");
    throw error;
  }
}

export function querySalesList({
  guidAccount,
  startDate,
  endDate,
  status,
}: ISalesListParams) {
  return {
    queryKey: ["sales", guidAccount, startDate, endDate, status],
    queryFn: () =>
      getSalesList({
        guidAccount,
        startDate,
        endDate,
        status,
      }),
  };
}

interface IUseSalesProps extends ISalesListParams {
  config?: IQueryConfig<typeof getSalesList>;
}

function select(data: ISalesReceiptItem, search: string) {
  if (!search) {
    return data;
  }

  const filteredData = data.items.filter((item) => {
    const searchTerm = Object.values(item).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(search.toLowerCase());
      }

      if (typeof value === "number") {
        return value.toString().includes(search);
      }

      return false;
    });

    return searchTerm;
  });

  return {
    ...data,
    items: filteredData,
  };
}

export function useSales({ startDate, endDate, config }: IUseSalesProps) {
  const [currentState] = useFilters();
  const { currentAccountId } = useAccountStore();

  const queryParams = {
    guidAccount: currentAccountId,
    startDate,
    endDate: endDate ?? startDate,
  };

  const queryBaseOptions = querySalesList(queryParams);

  const query = useQuery({
    ...queryBaseOptions,
    ...config,
    select: (data) => select(data, currentState.search),
  });

  return { query };
}
