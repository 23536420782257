import Icon from "@hyperlocal/vital-icons";
import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const CalendarHeader = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [EBreakPoints.mobile]: {
    flexDirection: "column" as const,
    alignItems: "flex-start",
    gap: spacings.stack.x3s, // 16px
  },
}));

export const CalendarHeaderTitle = styled.div(
  ({ theme: { fontSizes, fontWeights, palette } }) => ({
    display: "none",

    [EBreakPoints.mobile]: {
      display: "block",
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.bold,
      color: palette.neutral.darkest,
    },
  }),
);

export const CalendarHeaderMonthControllers = styled.div(() => ({
  display: "flex",

  [EBreakPoints.mobile]: {
    justifyContent: "space-between",
    width: "100%",

    "> button": {
      display: "none",
    },
  },

  "& > button:disabled": {
    cursor: "not-allowed",
  },
}));

export const CalendarHeaderMonth = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  alignItems: "center",
  gap: spacings.inline.x2s,

  [EBreakPoints.mobile]: {
    gap: 0,
  },
}));

export const CalendarHeaderMonthValue = styled.div(
  ({ theme: { fontSizes, fontWeights, palette } }) => ({
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.bold,
    color: palette.neutral.darkest,

    width: "9ch",
    textAlign: "center",

    [EBreakPoints.mobile]: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.regular,
      color: palette.primary.main,
    },
  }),
);

export const CalendarHeaderArrowButton = styled.button(
  ({ theme: { palette } }) => ({
    all: "initial",

    width: "16px",
    height: "16px",

    cursor: "pointer",

    svg: {
      fill: palette.neutral.darkest,
    },

    [EBreakPoints.mobile]: {
      width: "16px",
      height: "16px",

      svg: {
        fill: palette.primary.main,
      },
    },
  }),
);

export const CalendarHeaderArrow = Icon;

export const SwitchContainer = styled.div(
  ({
    theme: {
      spacings: { inline },
      palette,
      fontSizes,
    },
  }) => ({
    display: "flex",
    alignItems: "center",
    gap: inline.x2s,
    color: palette.neutral.darkest,

    [EBreakPoints.mobile]: {
      fontSize: fontSizes.x2s,
    },
  }),
);

export const SwitchPopoverContent = styled.p(
  ({ theme: { palette, lineHeights } }) => ({
    color: palette.primary.main,
    lineHeight: lineHeights.md,
  }),
);

export const SwitchPopoverTrigger = styled.div(({ theme: { palette } }) => ({
  display: "none",
  justifyContent: "center",
  alignItems: "center",

  "& svg": {
    fill: palette.primary.main,
  },

  [EBreakPoints.mobile]: {
    display: "flex",
  },
}));
