import { ComponentProps } from "react";
import {
  formatCurrency,
  getReceivedRoutesData,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { capitalize, groupBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import filter from "../../../../assets/filter_alt.svg";
import { ScrollArea } from "../../../../components/ScrollArea";
import { useAnticipation } from "../../api";
import {
  AnticipationListCard,
  AnticipationListCardSkeleton,
} from "./list-card";

export const AnticipationList = ({
  className,
  ...props
}: Omit<ComponentProps<"div">, "dir">) => {
  const routes = getReceivedRoutesData();
  const navigate = useNavigate();

  const { data, isLoading } = useAnticipation();

  if (isLoading) {
    return (
      <div
        className={twMerge("w-full space-y-4 overflow-y-auto", className)}
        {...props}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <div className="flex flex-col gap-2" key={index}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col justify-between gap-1 desktop:w-full desktop:flex-row desktop:items-start">
                <div className="h-[2.5ex] w-[20ch] animate-pulse rounded-xs bg-neutral-lighter" />

                <div className="h-[2ex] w-[10ch] animate-pulse rounded-xs bg-neutral-lighter" />
              </div>
            </div>

            {Array.from({ length: 3 }).map((_, index) => (
              <AnticipationListCardSkeleton key={index} />
            ))}
          </div>
        ))}
      </div>
    );
  }

  if (!data) {
    return (
      <span
        className={twMerge(
          "grid h-full place-content-center text-center text-xs/sm font-regular text-neutral-dark",
          className,
        )}
      >
        Não há antecipações recentes
      </span>
    );
  }

  const formattedData = data.spotAnticipationRequest?.map((anticipation) => ({
    ...anticipation,
    label: capitalize(
      format(new Date(anticipation.createdAt), "MMMM, yyyy", {
        locale: ptBR,
      }),
    ),
  }));

  const months = Object.entries(groupBy(formattedData, "label"));

  return (
    <ScrollArea className={twMerge("w-full", className)} {...props}>
      <div className="space-y-4">
        {months.map(([month, items], index) => {
          const totalMonthAnticipation = items.reduce(
            (acc, current) => acc + current.prepaymentGrossAmountRequested,
            0,
          );

          return (
            <div className="flex flex-col gap-2" key={month}>
              <div className="flex items-center justify-between">
                <div className="flex flex-col justify-between desktop:w-full desktop:flex-row desktop:items-start">
                  <h5 className="text-xs font-bold">{month}</h5>

                  <p className="text-x3s text-neutral-dark desktop:text-x2s">
                    Antecipação no mês: {formatCurrency(totalMonthAnticipation)}
                  </p>
                </div>

                {index === 0 && (
                  <div
                    className="flex cursor-pointer items-center justify-center gap-2 rounded-sm border border-primary-main p-2 text-x2s font-medium text-primary-main desktop:hidden"
                    onClick={() =>
                      navigate(routes.futureReceiptsAnticipationFilters)
                    }
                  >
                    <img src={filter} alt="Hyperlocal Logotipo" className="" />
                    Filtrar
                  </div>
                )}
              </div>

              {items.map((anticipation, index) => (
                <AnticipationListCard
                  key={index}
                  anticipation={anticipation}
                  anticipationTotalAmount={totalMonthAnticipation}
                  anticipationTotalCount={items.length}
                />
              ))}
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
};
