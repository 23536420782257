import { Fragment, useMemo } from "react";
import { Skeleton } from "@hyperlocal/vital";
import { UseQueryResult } from "@tanstack/react-query";
import { v4 } from "uuid";
import { formatToBRL } from "../../../../utils";
import { IGroupedItems } from "../../../../utils/formatSalesResponse";
import { ListItem } from "./ListItem";
import * as Styles from "./SalesPerPeriod.styles";
import { SkeletonListItem } from "./SkeletonListItem";

type IListProps = {
  data: UseQueryResult<IGroupedItems>["data"];
  isLoading?: UseQueryResult<IGroupedItems>["isLoading"];
  isError?: boolean;
};

export function List({ data, isLoading, isError }: IListProps) {
  const hasData = useMemo(() => Object.entries(data).length > 0, [data]);
  if (isLoading) {
    return (
      <Styles.List>
        <Styles.ListHeadings>
          <h5>
            <Skeleton width="185px" height="20px" />
          </h5>
          <p>
            <Skeleton width="285px" height="20px" />
          </p>
        </Styles.ListHeadings>

        {Array.from({ length: 6 }).map(() => {
          return <SkeletonListItem key={v4()} />;
        })}
      </Styles.List>
    );
  }

  if (!hasData || isError) {
    return (
      <Styles.List>
        <Styles.ListMessage>
          <p>
            {isError
              ? "Houve um problema ao buscar os dados."
              : "Nenhum valor recebido."}
          </p>
        </Styles.ListMessage>
      </Styles.List>
    );
  }

  return (
    <Styles.List>
      {Object.entries(data).map(([month, monthInfo]) => (
        <Fragment key={month}>
          <Styles.ListHeadings>
            <h5>{month}</h5>
            <p>Recebidos no mês {formatToBRL(monthInfo.total)}</p>
          </Styles.ListHeadings>
          <ListItem item={monthInfo.days} />

          <Styles.ListMessage>
            <p>
              Não há mais nada por aqui... <br />
              Para consultar datas anteriores use o filtro.
            </p>
          </Styles.ListMessage>
        </Fragment>
      ))}
    </Styles.List>
  );
}
