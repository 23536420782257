import styled from "styled-components";
import { EBreakPoints } from "../../../@types";

export const Container = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: props.theme.spacings.stack.x2s,

  position: "relative",
  overflow: "hidden",

  [EBreakPoints.mobile]: {
    position: "static",
    gridTemplateColumns: "1fr",
  },
}));
