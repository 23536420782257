import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import singleSpaReact from "single-spa-react";
import { Root } from "./root.component";
import "./index.css";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // renderType: "createRoot",
  errorBoundary() {
    return <ErrorBoundary FallbackComponent={ErrorFallback} />;
  },
});
