import VitalIcon from "@hyperlocal/vital-icons";
import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Container = styled.div(() => ({
  padding: "24px 0 8px 0",

  [EBreakPoints.mobile]: {
    padding: "8px",
  },
}));

export const Header = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: spacings.stack.x3s,
}));

export const HeaderContent = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  gap: spacings.inline.x2s,
}));

export const HeaderIcon = styled.div(
  ({ theme: { palette, borderRadius } }) => ({
    background: palette.primary.lighter,
    borderRadius: borderRadius.circle,

    width: "40px",
    height: "40px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    svg: {
      fill: palette.primary.main,
    },
  }),
);

export const HeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const HeaderTitle = styled.p(({ theme: { fontWeights, palette } }) => ({
  fontWeight: fontWeights.medium,
  color: palette.neutral.darkest,
}));

export const HeaderSubtitle = styled.sub(({ theme: { palette } }) => ({
  color: palette.neutral.dark,
}));

export const GrossValue = styled.span(
  ({ theme: { palette, fontSizes, fontWeights } }) => ({
    color: palette.primary.main,
    fontSize: fontSizes.md,
    fontWeight: fontWeights.bold,
  }),
);

export const GrossValueCurrency = styled.span(
  ({ theme: { fontSizes, fontWeights } }) => ({
    fontSize: fontSizes.x2s,
    fontWeight: fontWeights.regular,
  }),
);

export const Resume = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: spacings.stack.nano,

  paddingTop: spacings.stack.x3s,
}));

export const ResumeItem = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResumeItemLabel = styled.span(
  ({ theme: { fontWeights, palette } }) => ({
    fontWeight: fontWeights.medium,
    color: palette.neutral.darkest,
  }),
);

export type ResumeItemValueProps = {
  variant?: "NEGATIVE" | "POSITIVE";
};

export const ResumeItemValue = styled.span<ResumeItemValueProps>(
  ({ variant, theme: { fontWeights, palette, fontSizes } }) => {
    if (variant === "POSITIVE") {
      return {
        color: palette.primary.main,
        fontSize: fontSizes.sm,
        fontWeight: fontWeights.bold,
      };
    }

    if (variant === "NEGATIVE") {
      return {
        color: palette.status.error.errorDark,
        fontSize: fontSizes.x2s,
        fontWeight: fontWeights.regular,
      };
    }

    return {
      color: palette.neutral.dark,
      fontSize: fontSizes.x2s,
      fontWeight: fontWeights.regular,
    };
  },
);

export const ResumeCurrencySignal = styled.span(
  ({ theme: { fontSizes, fontWeights } }) => ({
    fontSize: fontSizes.x2s,
    fontWeight: fontWeights.regular,
  }),
);

type ResumeDetailsProps = { showAllDetails: boolean };

export const ResumeDetails = styled.span<ResumeDetailsProps>(
  ({ theme: { spacings, palette, fontWeights }, showAllDetails }) => ({
    display: "flex",
    margin: "0 auto",
    alignItems: "center",
    gap: spacings.inline.x3s,
    cursor: "pointer",
    color: palette.primary.main,

    marginTop: spacings.inline.x2s,

    fontWeight: fontWeights.medium,
    textDecoration: "underline",

    svg: {
      fill: palette.primary.main,
      transform: showAllDetails ? "rotate(0deg)" : "rotate(180deg)",
      transition: "transform 0.2s ease-in-out",
    },
  }),
);
