// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getReceivedRoutesData } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";

export const DisplayAnticipations = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex cursor-pointer items-center justify-between rounded-lg border border-primary-main px-4 py-3 text-primary-main"
      onClick={() =>
        navigate(getReceivedRoutesData().futureReceiptsAnticipation)
      }
    >
      <div className="flex gap-2">
        <Icon name="MoneyAntecipate" className="size-6 fill-primary-main" />

        <span className="text-xs font-bold">Pedidos de antecipação</span>
      </div>

      <Icon name="ArrowArrowNoLineRight" className="size-4 fill-primary-main" />
    </div>
  );
};
