import styled from "styled-components";
import { EBreakPoints } from "../../@types";

export const ButtonContainer = styled.button((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  height: "100%",

  background: "transparent",
  border: "none",

  "& div": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  "& svg": {
    width: 12.5,
    height: 12.5,

    "& path": {
      fill: props.theme.palette.neutral.darkest,
    },
    "& g": {
      stroke: props.theme.palette.neutral.darkest,
    },
  },

  "& p": {
    marginLeft: props.theme.spacings.stack.nano,
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
  },

  ".iconWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 48,
    height: 48,

    margin: 0,

    background: "transparent",
    border: "none",
  },
}));

export const Container = styled.div(
  ({
    theme: { fonts, fontWeights, fontSizes, lineHeights, palette, spacings },
  }) => ({
    "& > h5": {
      fontFamily: fonts.base,
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      color: palette.neutral.darker,

      marginTop: spacings.stack.x2s,
      marginBottom: spacings.stack.x3s,

      [EBreakPoints.mobile]: {
        fontSize: fontSizes.x2s,
        fontWeight: fontWeights.regular,
      },
    },
    "& > div": {
      display: "flex",
      flexDirection: "column",
      padding: `12px 0 12px ${spacings.stack.x3s}`,
      minWidth: 416,
      height: 48,
      border: "2px solid #E5E5E5",
      [EBreakPoints.mobile]: {
        minWidth: "100%",
      },
    },

    "& div + div": {
      marginTop: spacings.stack.x3s,
    },
  }),
);
