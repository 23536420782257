import { IReactToPrintProps, useReactToPrint } from "react-to-print";

export const usePrint = ({
  content,
  documentTitle,
  ...rest
}: IReactToPrintProps) => {
  return useReactToPrint({
    content,
    documentTitle: documentTitle || "Comprovante",
    ...rest,
  });
};
