import { useState } from "react";
import { getReceivedRoutesData, Hooks, useAccountStore } from "@/utils";
import { anticipationErrorMessages } from "@/utils/constants";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";
import { AxiosError } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAnticipate } from "../../api/useAnticipate";
import { AnticipationReceiptSheet } from "../AnticipationReceipt";

const dictionary = {
  trigger: "Antecipar",
  title: "Atenção",
  description: (
    <>
      A <b>sssssolicitação de antecipação</b> pode levar até dois dias úteis
      para ser processada. Além disso, você só pode{" "}
      <b>solicitar a antecipação uma vez por dia</b>. Você tem certeza de que
      deseja prosseguir com a antecipação?
    </>
  ),
  cancel: "Cancelar",
  anticipate: "Antecipar",
};

type AnticipationConfirmationProps = {
  nsuList: string[];
  isDisabled: boolean;
};

export const AnticipationConfirmation = ({
  nsuList,
  isDisabled,
}: AnticipationConfirmationProps) => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { futureReceiptsAnticipationReceipt } = getReceivedRoutesData();
  const { account } = useAccountStore();
  const guidCustomer =
    account.personInfoResponse?.customerId ||
    account.companyInfoResponse.customerId;

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const anticipationMutation = useAnticipate();

  const handleConfirmAnticipation = () => {
    anticipationMutation.mutate(
      {
        guidCustomer,
        installmentsNsuList: nsuList,
      },
      {
        onSuccess: ({ guidSpotRequests: anticipationId, ocurrenceMessage }) => {
          if (
            anticipationErrorMessages.some((errorMessage) =>
              ocurrenceMessage.trim().startsWith(errorMessage),
            )
          )
            return toast({
              title: "Erro ao antecipar",
              description:
                ocurrenceMessage ??
                "Ocorreu um erro ao solicitar a antecipação",
              variant: "error",
              descriptionClassName: "line-clamp-none bg-blue-500",
            });

          setSearchParams({ anticipationId });

          if (isMobile) {
            return navigate(
              `${futureReceiptsAnticipationReceipt}?anticipationId=${anticipationId}`,
            );
          }

          setIsSheetOpen(true);
        },
        onError: (error: AxiosError<{ errors: { Messages: string[] } }>) => {
          toast({
            title: "Erro ao antecipar",
            description:
              error.response.data?.errors?.Messages[0] ??
              "Ocorreu um erro ao solicitar a antecipação",
            variant: "error",
          });
        },
      },
    );
  };

  if (isMobile) {
    return (
      <Drawer.Root>
        <Drawer.Trigger asChild>
          <Button.Root
            className="w-full"
            variant="primary"
            disabled={isDisabled}
          >
            {dictionary.trigger}
          </Button.Root>
        </Drawer.Trigger>

        <Drawer.Content className="pb-9">
          <Drawer.Header>
            <Drawer.Title>{dictionary.title}</Drawer.Title>
          </Drawer.Header>

          <p className="mt-6">{dictionary.description}</p>

          <div className="mt-6 grid w-full grid-cols-2 space-x-2">
            <Drawer.Close asChild>
              <Button.Root variant="secondary">{dictionary.cancel}</Button.Root>
            </Drawer.Close>

            <Button.Root
              variant="primary"
              onClick={() => handleConfirmAnticipation()}
              isLoading={anticipationMutation.isLoading}
            >
              {dictionary.anticipate}
            </Button.Root>
          </div>
        </Drawer.Content>
      </Drawer.Root>
    );
  }

  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button.Root
            className="w-full"
            variant="primary"
            disabled={isDisabled}
          >
            {dictionary.trigger}
          </Button.Root>
        </Dialog.Trigger>

        <Dialog.Content className="gap-0">
          <Dialog.Header>
            <Dialog.Title>{dictionary.title}</Dialog.Title>
          </Dialog.Header>

          <p className="mt-2">{dictionary.description}</p>

          <Dialog.Footer className="mt-6 grid w-full grid-cols-2 space-x-2">
            <Dialog.Close asChild>
              <Button.Root variant="secondary">Cancelar</Button.Root>
            </Dialog.Close>

            <Button.Root
              onClick={() => handleConfirmAnticipation()}
              isLoading={anticipationMutation.isLoading}
            >
              Antecipar
            </Button.Root>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>

      <AnticipationReceiptSheet
        open={isSheetOpen}
        onOpenChange={setIsSheetOpen}
      />
    </>
  );
};
