import { formatCurrency } from "@/utils";
import { Skeleton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { format, isSameDay } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CalendarTableProps, TableRowsData } from ".";
import { capitalizeFirstLetter } from "../../../../utils";
import * as S from "./styles";

type CalendarTableRowProps = {
  date: Date;

  onSelectRange: CalendarTableProps["onSelectRange"];
  selectedRange: CalendarTableProps["selectedRange"];
  selectionMode: CalendarTableProps["selectionMode"];

  isDisabled: boolean;
  rowInfo: TableRowsData;
  isLoading: boolean;
};

export const CalendarTableRow = (props: CalendarTableRowProps) => {
  const {
    date,
    onSelectRange,
    selectedRange,
    selectionMode,
    isDisabled,
    rowInfo,
    isLoading,
  } = props;

  const [day, dayOfWeek] = [
    format(date, "dd", {
      locale: ptBR,
    }),
    capitalizeFirstLetter(
      format(date, "EEE", {
        locale: ptBR,
      }),
    ).slice(0, 3), // sábado -> Sábado -> Sáb
  ];

  const isCurrent = isSameDay(date, new Date());

  const handleSelectRange = () => {
    if (selectionMode !== "RANGE") {
      return;
    }

    if (!selectedRange) {
      onSelectRange({ start: date, end: null });
      return;
    }

    if (!selectedRange.start && !selectedRange.end) {
      onSelectRange({ start: date, end: null });
      return;
    }

    if (date < selectedRange.start) {
      onSelectRange({ start: date, end: selectedRange.end });
      return;
    }

    if (date > selectedRange.start && !selectedRange.end) {
      onSelectRange({ start: selectedRange.start, end: date });
      return;
    }

    onSelectRange({ start: null, end: null });
  };

  const isSelected =
    isSameDay(date, selectedRange?.start) ||
    isSameDay(date, selectedRange?.end);

  return (
    <S.Tr
      onClick={selectionMode === "RANGE" ? handleSelectRange : undefined}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <S.Td>
        <S.Day isCurrent={isCurrent}>
          <p>{day}</p>
          <p>{dayOfWeek}</p>
        </S.Day>
      </S.Td>

      <S.Td>
        {isLoading ? (
          <Skeleton width="32px" height="16px" borderRadius="4px" />
        ) : (
          <S.TdValue>{rowInfo.totalItems}</S.TdValue>
        )}
      </S.Td>

      <S.Td>
        <S.TdValue>
          {isLoading ? (
            <Skeleton width="32px" height="16px" borderRadius="4px" />
          ) : (
            formatCurrency(rowInfo.totalAmount)
          )}
        </S.TdValue>
      </S.Td>

      <S.Td>
        <Icon
          name="ArrowArrowNoLineRight"
          width={16}
          height={16}
          onClick={() =>
            onSelectRange({
              end: date,
              start: date,
            })
          }
        />
      </S.Td>
    </S.Tr>
  );
};
