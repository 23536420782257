import { ReactNode } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import { Skeleton } from "@hyperlocal/vital";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import * as S from "./styles";

type CalendarCardProps = { children: ReactNode };

const CardRoot = ({ children }: CalendarCardProps) => {
  return <S.Card>{children}</S.Card>;
};

type CalendarContentProps = { children: ReactNode };

const CardContent = ({ children }: CalendarContentProps) => {
  return <S.CardContent>{children}</S.CardContent>;
};

type CalendarIconProps = { type: S.CardType };

const CardIcon = ({ type }: CalendarIconProps) => {
  const iconName: Record<S.CardType, IconProps["name"]> = {
    GROSS: "GeralMoneyMoney",
    NET: "GeralMoneyPayDemand",
  };

  return (
    <S.CardIconContainer type={type}>
      <Icon name={iconName[type]} />
    </S.CardIconContainer>
  );
};

type CardAmountProps = {
  amount: number;
  type: S.CardType;
  loading: boolean;
};

export const CardAmount = ({ type, amount, loading }: CardAmountProps) => {
  const label: Record<S.CardType, string> = {
    GROSS: "Valor bruto",
    NET: "Valor líquido",
  };

  if (loading) {
    return (
      <S.CardAmount>
        <S.CardLabel>{label[type]}</S.CardLabel>

        <S.CardAmountValue>
          <Skeleton width="16px" height="16px" borderRadius="4px" />
          vendas
        </S.CardAmountValue>
      </S.CardAmount>
    );
  }

  return (
    <S.CardAmount>
      <S.CardLabel>{label[type]}</S.CardLabel>
      <S.CardAmountValue>{amount} vendas</S.CardAmountValue>
    </S.CardAmount>
  );
};

type CalendarValueProps = {
  value: number;
  type: S.CardType;
  loading: boolean;
};

export const CalendarValue = ({ value, type, loading }: CalendarValueProps) => {
  if (loading) {
    return <Skeleton width="64px" height="24px" borderRadius="4px" />;
  }

  return <S.CardValue type={type}>{formatCurrency(value || 0)}</S.CardValue>;
};

export const CalendarCard = {
  Root: CardRoot,
  Content: CardContent,
  Icon: CardIcon,
  Amount: CardAmount,
  Value: CalendarValue,
};
