import { Layout } from "../../../components";
import { Details } from "../components";

export function ReceivedValueDetail() {
  return (
    <Layout>
      <Details />
    </Layout>
  );
}
