/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { toast, useAccountStore } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "../../../hooks";
import { api, IQueryConfig } from "../../../lib";
import { ISalesReceiptItem } from "../../../utils/formatSalesResponse";
import { useFilters } from "../stores";
import { EQueryKeys } from "../types";

interface IGetReceivedListProps {
  guid?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  timeout?: number;
  config?: IQueryConfig<typeof getReceivedList>;

  pageParam?: number;
}

const ONE_MINUTE = 60000;

export async function getReceivedList({
  guid,
  startDate,
  endDate,
  status = "",
  timeout = ONE_MINUTE,
}: IGetReceivedListProps): Promise<ISalesReceiptItem | null> {
  try {
    const response = await api.get<ISalesReceiptItem>(
      `/Sales/${guid}/Receipts/Items/date`,
      {
        timeout: 60000,
        params: {
          startDate,
          endDate,
          status,
          timeout,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log("🚀 ~ file: getReceivedList.ts:67 ~ error:", error);
    toast.error("Houve um problema ao buscar os dados.");
    throw error;
  }
}

export function queryPlanList({
  endDate,
  guid,
  startDate,
  status = "",
  timeout = ONE_MINUTE,
}: IGetReceivedListProps) {
  return {
    queryKey: [EQueryKeys.ReceivedList, startDate, endDate, guid, status],
    queryFn: () =>
      getReceivedList({ guid, startDate, endDate, status, timeout }),
  };
}

export function useReceivedList({
  status = "",
  endDate,
  startDate,
  guid,
  timeout = ONE_MINUTE,
  config = {},
}: IGetReceivedListProps = {}) {
  const [currentState] = useFilters();
  const debouncedSearch = useDebounce(currentState.search, 300);
  const { currentAccountId } = useAccountStore();

  const queryPlanListParams = {
    guid: guid || currentAccountId,
    startDate,
    endDate,
    status,
    timeout,
  };

  const queryBaseOptions = queryPlanList(queryPlanListParams);

  const query = useQuery({
    ...queryBaseOptions,
    onError: (error) => {
      console.error(error);
    },
    select(data) {
      if (!currentState.search) {
        return data;
      }

      const filteredData = data.items.filter((item) => {
        const searchTerm = Object.values(item).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(debouncedSearch.toLowerCase());
          }

          if (typeof value === "number") {
            return value
              .toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
              .includes(debouncedSearch);
          }

          return false;
        });

        return searchTerm;
      });

      return {
        ...data,
        items: filteredData,
      };
    },
    ...config,
  });

  return { query };
}
