import { IModalProps, Modal } from "../../../../components";

type IPortabilityAndClaimsModalProps = Partial<IModalProps> & {
  openConfirmAdvanceModal: () => void;
};

export function PortabilityAndClaimsModal({
  isOpen,
  onClose,
  openConfirmAdvanceModal,
}: IPortabilityAndClaimsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ width: 343, height: 281 }}>
      <Modal.Header onClose={onClose}>
        Portabilidades e Reivindicações
      </Modal.Header>
      <Modal.Content>
        <p>
          Você pode antecipar todas as suas vendas ou selecionar quais quer
          antecipar.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Button
          variant="primary"
          onClick={() => {
            openConfirmAdvanceModal();
            onClose();
          }}
        >
          Antecipar Todas
        </Modal.Button>
        <Modal.Button
          variant="secondary"
          onClick={() => {
            // TODO: open modal to select values by day
          }}
        >
          Selecionar valores por dia
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}
