export interface ISalesReceiptItem {
  advance_value: number;
  bank_address: string | null;
  brand_description: string;
  discount_value: number;
  gross_value: number;
  guid_onlead_item: string | null;
  installment_number: number;
  net_value: number;
  nsu: string | null;
  processing_date: string;
  status: string;
  terminal_serial_number: string;
  total_installments: number;
  transaction_date: string;
  transaction_product: string;
  type: string;
}

export interface ISalesReceipt {
  advance_value: number;
  discount_value: number;
  gross_value: number;
  items: ISalesReceiptItem[];
  net_value: number;
}

export interface IBankStatementExtract {
  amount: number;
  date: Date;
  type: string;
  summary: string;
  partnerKey?: null | string;
  operation_type: string;
}

export interface IExportedDocument {
  Data: string;
  "Valor bruto(R$)": number;
  Parcelas: number;
  Status: string;
  Bandeira: string;
  NSU: string;
  Terminal: string;
}

export enum EQueryKeys {
  ReceivedList = "receivedList",
  BankStatementExtract = "bankStatementExtract",
  ExportedDocument = "exportedDocument",
  ReceivedByCalendar = "receivedByCalendar",
}
