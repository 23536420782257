import { ComponentProps, PropsWithChildren, useEffect, useRef } from "react";
import { VerticalLogo } from "@/components";
import { usePrint } from "@/hooks/usePrint";
import {
  formatCurrency,
  getReceivedRoutesData,
  Hooks,
  setHeader,
  useAccountStore,
} from "@/utils";
import {
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";
import { Button, Sheet, SheetRootProps } from "@hyperlocal/vital2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAnticipationDetails } from "../../api/useAnticipationDetails";
import { LoadingReceipt } from "./loading";

const formatDocument = (document: string) => {
  if (isValidCPF) return formatCPF(document);
  if (isValidCNPJ) return formatCNPJ(document);

  return document;
};

const AnticipationReceiptItem = {
  Root: ({
    className,
    ...props
  }: PropsWithChildren & ComponentProps<"div">) => (
    <div className={twMerge("grid grid-cols-2", className)} {...props} />
  ),
  Label: (props: PropsWithChildren) => (
    <span className="font-medium text-neutral-darkest" {...props} />
  ),
  Value: ({
    className,
    ...props
  }: PropsWithChildren & ComponentProps<"span">) => (
    <span
      className={twMerge("text-end text-neutral-dark", className)}
      {...props}
    />
  ),
};

type AnticipationReceiptProps = ComponentProps<"div">;
export const AnticipationReceipt = ({
  className,
  ...props
}: AnticipationReceiptProps) => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const routes = getReceivedRoutesData();

  const [searchParams, setSearchParams] = useSearchParams();
  const anticipationId = searchParams.get("anticipationId");
  const { account } = useAccountStore();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });
  const { data, isLoading } = useAnticipationDetails(anticipationId);

  useEffect(() => {
    if (isMobile) {
      setHeader({
        title: "Comprovante",
        rightIcon: {
          name: "GeralClose",
          onClick: () => {
            if (searchParams.get("anticipationId")) {
              searchParams.delete("anticipationId");
              setSearchParams(searchParams);
            }

            navigate(getReceivedRoutesData().futureReceiptsCalendar);
          },
        },
      });
    }

    return () => {
      setHeader({});
    };
  }, [isMobile, navigate, searchParams, setSearchParams]);

  if (isLoading)
    return (
      <div className="h-full">
        <LoadingReceipt />
      </div>
    );

  const total = data?.spotRequestReceivables?.reduce(
    (total, cur) => {
      total.totalRequested += cur.installmentAmount;

      total.totalNetValue += cur.anticipationAmount;

      total.totalFees += cur.installmentAmount - cur.anticipationAmount;

      return total;
    },
    {
      totalRequested: 0,
      totalFees: 0,
      totalNetValue: 0,
    },
  );

  const items = [
    {
      label: "Data de recebimento",
      value: new Intl.DateTimeFormat("pt-BR").format(
        new Date(data.spotAnticipationRequest.paymentDate),
      ),
    },
    {
      label: "Favorecido",
      value: account.personInfoResponse.name,
    },
    {
      label: "Valor antecipado",
      value: formatCurrency(total.totalRequested),
    },
    {
      label: "Descontos de taxas",
      value: `-${formatCurrency(total.totalFees)}`,
    },
    {
      label: "Valor a receber",
      value: formatCurrency(total.totalNetValue),
    },
    {
      label: "CPF/CNPJ",
      value: formatDocument(
        account.personInfoResponse.document ||
          account.companyInfoResponse.document,
      ),
    },
  ];

  return (
    <div
      className={twMerge(
        "flex h-full flex-col justify-between space-y-4",
        className,
      )}
      {...props}
    >
      <div ref={componentRef}>
        <div className="mt-8 flex flex-col items-center justify-center space-y-6 text-center">
          <VerticalLogo />
          <div className="space-y-1 text-sm font-bold">
            <h1 className="text-neutral-darkest">Antecipação</h1>
            <p className="text-primary-main">Solicitada com sucesso!</p>
          </div>
        </div>

        <div className="mt-6 space-y-4 border-y py-4">
          {items.map((item) => {
            return (
              <AnticipationReceiptItem.Root key={item.label}>
                <AnticipationReceiptItem.Label>
                  {item.label}
                </AnticipationReceiptItem.Label>

                <AnticipationReceiptItem.Value>
                  {item.value}
                </AnticipationReceiptItem.Value>
              </AnticipationReceiptItem.Root>
            );
          })}
        </div>

        <div className="mt-4">
          <AnticipationReceiptItem.Root className="flex flex-col">
            <AnticipationReceiptItem.Label>
              Id da Operação
            </AnticipationReceiptItem.Label>

            <AnticipationReceiptItem.Value className="text-start">
              {anticipationId}
            </AnticipationReceiptItem.Value>
          </AnticipationReceiptItem.Root>
        </div>
      </div>

      <Sheet.Footer className="flex-col gap-4">
        <>
          <Button.Root className="w-full desktop:hidden" onClick={handlePrint}>
            <Button.Slot name="ComputersShare" />
            Compartilhar comprovante
          </Button.Root>

          <Button.Root className="w-full mobile:hidden" onClick={handlePrint}>
            <Button.Slot name="ComputersPrinter" />
            Imprimir comprovante
          </Button.Root>
        </>

        <Button.Root
          className="w-full"
          variant="link"
          onClick={() => navigate(routes.futureReceiptsAnticipation)}
        >
          Acompanhar antecipação
        </Button.Root>
      </Sheet.Footer>
    </div>
  );
};

type AnticipationReceiptSheetProps = SheetRootProps;
export const AnticipationReceiptSheet = ({
  open,
  onOpenChange,
}: AnticipationReceiptSheetProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenChange = (open: boolean) => {
    if (!open && searchParams.has("anticipationId")) {
      searchParams.delete("anticipationId");
      setSearchParams(searchParams);
    }

    onOpenChange(open);
  };

  return (
    <Sheet.Root open={open} onOpenChange={handleOpenChange}>
      <Sheet.Content className="z-[1004] flex justify-between">
        <Sheet.Header className="flex w-full flex-row items-center justify-between">
          <div className="text-md font-bold text-neutral-darkest">
            Comprovante
          </div>
        </Sheet.Header>

        {open && <AnticipationReceipt />}
      </Sheet.Content>
    </Sheet.Root>
  );
};
