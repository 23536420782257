export interface IItem {
  guid_onlead_item: string | null;
  transaction_date: string;
  processing_date: string;
  status: string;
  gross_value: number;
  discount_value: number;
  advance_value: number;
  net_value: number;
  brand_description: string;
  transaction_product: string;
  installment_number: number;
  total_installments: number;
  nsu: string | null;
  terminal_serial_number: string;
  type: string;
  bank_address: null;
}

export interface ISalesReceiptItem {
  advance_value: number;
  discount_value: number;
  gross_value: number;
  net_value: number;
  items: IItem[];
}

export interface IDaysInfo {
  [day: string]: {
    total: number;
    items: IItem[];
  };
}

export interface IGroupedItems {
  [month: string]: {
    total: number;
    days: IDaysInfo;
  };
}

const getGroupDate = (date: string) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    month: "long",
  });
};

export function formatSalesResponse(data: ISalesReceiptItem) {
  try {
    const items = data.items.reduce((acc, item) => {
      const groupedDate = getGroupDate(item.processing_date);

      const writtenMonth =
        groupedDate.charAt(0).toUpperCase() + groupedDate.slice(1);

      const day = new Date(item.processing_date).toLocaleDateString();

      if (!acc[writtenMonth]) {
        acc[writtenMonth] = {
          total: 0,
          days: {},
        };
      }

      if (!acc[writtenMonth].days[day]) {
        acc[writtenMonth].days[day] = { total: 0, items: [] };
      }

      acc[writtenMonth].days[day].total += item.net_value;
      acc[writtenMonth].total += item.net_value;
      acc[writtenMonth].days[day].items.push(item);

      return acc;
    }, {} as IGroupedItems);

    return items;
  } catch (error) {
    return {};
  }
}
