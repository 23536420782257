import { api } from "@/lib";
import { useAccountStore } from "@/utils";
import { anticipationKeys } from "@/utils/queryFactory";
import { useQuery } from "@tanstack/react-query";

export type AnticipationData = {
  totalNetAmount: number;
  totalGrossAmount: number;
  dateMin?: string;
  dateMax?: string;
  receivableAgenda: Array<{
    date: string;
    grossAmount: number;
    netAmount: number;
    antecipationNetAmount: number;
    receivables: Array<{
      receivableId: string;
      transactionNsu: string;
      instalmentNsu: string;
      // ...
    }>;
    // ...
  }>;
};

const getReceivableData = async (guidCustomer: string) => {
  const { data } = await api.get<AnticipationData>(
    `/Sales/${guidCustomer}/Receivables/FutureAgenda`,
  );

  return data;
};

export const useGetReceivables = () => {
  const { account } = useAccountStore();

  const { customerId: guidCustomer } =
    account.companyInfoResponse || account.personInfoResponse;

  return useQuery({
    queryKey: anticipationKeys.receivables(),
    queryFn: async () => await getReceivableData(guidCustomer),
  });
};
