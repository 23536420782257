/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Suspense, useEffect } from "react";
import {
  getDashboardRoutesData,
  getSalesRoutesData,
  Hooks,
  setHeader,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../../components";
import { useDisclosure } from "../../../hooks";
import { Display, Export, Filters, Skeleton } from "../components";
import * as Styles from "./Root.styles";

export function Root() {
  const isDesktop = Hooks.useMediaQuery("desktop");
  const exportModal = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    setHeader({
      title: "Recebimentos",
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => {
          navigate(getSalesRoutesData().dashboard);
        },
      },
    });
    return () => {
      setHeader({});
    };
  }, [exportModal, isDesktop]);

  return (
    <Layout
      title="Recebimentos"
      breadcrumb={[
        { label: "Pay", route: getDashboardRoutesData().dashboardPay },
        { label: "Recebimentos" },
      ]}
      backTo={getDashboardRoutesData().dashboardPay}
    >
      <Styles.Container>
        {isDesktop ? <Filters /> : null}
        <Suspense fallback={<Skeleton />}>
          <Display />
        </Suspense>
      </Styles.Container>
      <Suspense fallback={null}>
        <Export isOpen={exportModal.isOpen} onClose={exportModal.close} />
      </Suspense>
    </Layout>
  );
}
