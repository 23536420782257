/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from "react";
// @ts-ignore
import { getReceivedRoutesData, Hooks } from "@hyperlocal/banking-utility";
import { Input } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { Card, ErrorFallback } from "../../../../components";
import { TODAY } from "../../../../config";
import { useDisclosure } from "../../../../hooks";
import {
  formatDateToApi,
  formatSalesResponse,
  formatTotalSalesToView,
} from "../../../../utils";
import { useReceivedList } from "../../api";
import { useFilters } from "../../stores";
import { Export } from "../Export";
import { List } from "./List";
import * as Styles from "./SalesPerPeriod.styles";

export function SalesPerPeriod() {
  const navigate = useNavigate();
  const exportFilesDrawer = useDisclosure();
  const searchDisclosure = useDisclosure();
  const isMobile = Hooks.useMediaQuery("mobile");
  const [currentState, send] = useFilters();

  const {
    query: { data, isLoading, isError: isQueryError },
  } = useReceivedList({
    startDate: currentState.startDate
      ? formatDateToApi(currentState.startDate)
      : formatDateToApi(TODAY),
    endDate: currentState.endDate
      ? formatDateToApi(currentState.endDate)
      : formatDateToApi(TODAY),
    config: {
      suspense: false,
    },
  });

  const cardValues = useMemo(() => {
    return {
      value: data?.net_value || 0,
      subtitle: formatTotalSalesToView(data?.items.length || 0),
    };
  }, [data]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Styles.Container>
        <Card
          avatar={{
            icon: "MoneyDollarCircle",
            variant: "primary",
            size: "md",
          }}
          title="Vendas do período"
          subTitle={cardValues.subtitle}
          value={cardValues.value}
          width="100%"
          height="100%"
          isloading={isLoading}
        />

        <Styles.ListContainer>
          {isMobile ? (
            <>
              <Styles.FiltersRow>
                <div>
                  <Styles.Button
                    leftIcon={{
                      name: "FilterFilter",
                    }}
                    onClick={() =>
                      navigate(getReceivedRoutesData().receivedFillters)
                    }
                  >
                    Filtros
                  </Styles.Button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => exportFilesDrawer.open()}
                  >
                    <Icon name="ArrowExportRectangle" />
                  </button>

                  <button type="button" onClick={searchDisclosure.toggle}>
                    <Icon name="SearchNormal" />
                  </button>
                </div>
              </Styles.FiltersRow>

              {searchDisclosure.isOpen && (
                <Input
                  data-visible={searchDisclosure.isOpen}
                  type="search"
                  placeholder="Pesquisar"
                  value={currentState.search}
                  onChange={(e) => {
                    send({
                      type: "SET_SEARCH",
                      payload: { search: e.target.value },
                    });
                  }}
                />
              )}
            </>
          ) : (
            <Styles.Button
              leftIcon={{
                name: "ArrowExportRectangle",
              }}
              onClick={() => exportFilesDrawer.open()}
            >
              Exportar
            </Styles.Button>
          )}

          <List
            data={formatSalesResponse(data)}
            isLoading={isLoading}
            isError={isQueryError}
          />
        </Styles.ListContainer>
      </Styles.Container>

      <Export
        isOpen={exportFilesDrawer.isOpen}
        onClose={exportFilesDrawer.close}
      />
    </ErrorBoundary>
  );
}
