// @ts-ignore
import { toast } from "@hyperlocal/banking-utility";
import { useMutation } from "@tanstack/react-query";
import { api, IMutationConfig } from "../lib";

export interface IDocumentBody {
  "Data de processamento": string;
  "Data da transação": string;
  "Valor bruto(R$)": string;
  Parcela: number;
  "Nº de parcelas": number;
  Tipo: null | string;
  Operação: string;
  Destino: string;
  "Taxa da transação(R$)": string;
  "Taxa da antecipação(R$)": string;
  "Valor líquido(R$)": string;
  Bandeira: string;
  NSU: string;
  Terminal: string;
}

interface IDownloadDocumentProps {
  extension: "pdf" | "xlsx";
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
}

export async function downloadDocument({
  extension,
  body,
  name,
}: IDownloadDocumentProps) {
  try {
    const response = await api.post(`desk/export/${extension}`, body, {
      responseType: "arraybuffer",
      headers: {
        Accept:
          extension === "pdf" ? "application/pdf" : "application/vnd.ms-excel",
      },
    });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${name}.${extension}`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log("🚀 ~ file: downloadDocument.ts:52 ~ error:", error);
    toast.error("Houve um erro ao tentar baixar o documento.");
  }
}

export function downloadDocumentMutation() {
  return {
    queryKey: ["downloadDocument"],
    mutationFn: downloadDocument,
  };
}

interface IUseDownloadDocumentProps {
  config?: IMutationConfig<typeof downloadDocument>;
}

export function useDownloadDocument(config: IUseDownloadDocumentProps = {}) {
  return useMutation({
    ...config,
    mutationKey: ["downloadDocument"],
    mutationFn: (data: IDownloadDocumentProps) => downloadDocument(data),
  });
}
