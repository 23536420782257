import { ExportFilesDrawer } from "../../../../components";
import { TODAY } from "../../../../config";
import { formatDateToApi, IItem } from "../../../../utils";
import { useReceivedList } from "../../api";
import { useFilters } from "../../stores";

interface IExportProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Export({ isOpen, onClose }: IExportProps) {
  const [currentState] = useFilters();

  const {
    query: { data: queryData },
  } = useReceivedList({
    startDate: currentState.startDate
      ? formatDateToApi(currentState.startDate)
      : formatDateToApi(TODAY),
    endDate: currentState.endDate
      ? formatDateToApi(currentState.endDate)
      : formatDateToApi(TODAY),
    config: {
      suspense: false,
    },
  });

  const formatItem = (item: IItem) => {
    return {
      "Data de processamento": item.processing_date,
      "Data da transação": item.transaction_date,
      "Valor bruto(R$)": item.gross_value.toFixed(2),
      Parcela: item.installment_number,
      "Nº de parcelas": item.total_installments,
      Tipo: item.transaction_product,
      Operação: item.type,
      Destino: "",
      "Taxa da transação(R$)": item.discount_value.toFixed(2),
      "Taxa da antecipação(R$)": item.advance_value.toFixed(2),
      "Valor líquido(R$)": item.net_value.toFixed(2),
      Bandeira: item.brand_description,
      NSU: item.nsu,
      Terminal: item.terminal_serial_number,
    };
  };

  const getDocumentBodies = () => {
    const body = queryData?.items?.reduce((acc, item) => {
      const formattedItem = formatItem(item);

      return acc.concat(formattedItem);
    }, []);

    return body;
  };

  return (
    <ExportFilesDrawer
      isOpen={isOpen}
      onClose={onClose}
      fileName="valores_recebidos"
      data={getDocumentBodies()}
    />
  );
}
