// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { useMutation } from "@tanstack/react-query";

type Response = {
  guidSpotRequests: string;
  paymentDate: string;
  ocurrenceMessage: string;
};

type Props = {
  guidCustomer: string;
  installmentsNsuList: string[];
};

export const useAnticipate = () => {
  return useMutation({
    mutationFn: async ({ guidCustomer, installmentsNsuList }: Props) => {
      const response = await API.spot.post<Response>(
        "/api/SpotReceivables/Banking/InstallmentAnticipation",
        {
          guidCustomer,
          installmentsNsuList,
        },
      );

      return response.data;
    },
  });
};
