/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Fragment, useMemo } from "react";
// @ts-ignore
import { getReceivedRoutesData, Hooks } from "@hyperlocal/banking-utility";
import { Input } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../../components";
import { useDisclosure } from "../../../../hooks";
import {
  formatSalesResponse,
  formatToBRL,
  formatTotalSalesToView,
} from "../../../../utils";
import { IItem, useSales } from "../../api";
import { useFilters } from "../../stores";
import { ConfirmAdvanceModal } from "../ConfirmAdvanceModal";
import { DetailsModal } from "../DetailsModal";
import { Export } from "../Export";
import { PortabilityAndClaimsModal } from "../PortabilityAndClaimsModal";
import { TermsOfUseModal } from "../TermsOfUseModal";
import * as Styles from "./Display.styles";
import { DisplayAnticipations } from "./DisplayAnticipations";

export function Display() {
  const portabilityAndClaimsModal = useDisclosure();
  const exportFileDisclosure = useDisclosure();
  const confirmAdvanceModal = useDisclosure();
  const searchDisclosure = useDisclosure();
  const termsOfUseModal = useDisclosure();
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const modal = useDisclosure<{
    date: string;
    value: number;
    items: IItem[];
  }>();

  const [currentState, send] = useFilters();

  const {
    query: { data: queryData },
  } = useSales({
    startDate: currentState.startDate.toISOString().split("T")[0],
    endDate: currentState.endDate.toISOString().split("T")[0],
    status: "SCHEDULED",
    config: {
      enabled: !!currentState.startDate && !!currentState.endDate,
    },
  });

  const data = useMemo(() => {
    const formattedData = Object.entries(formatSalesResponse(queryData));
    return formattedData;
  }, [queryData]);

  const value = useMemo(() => {
    return queryData?.net_value;
  }, [queryData]);

  const subTitle = useMemo(() => {
    return formatTotalSalesToView(queryData?.items?.length || 0);
  }, [queryData]);

  return (
    <>
      <Styles.Container>
        <Card
          avatar={{
            icon: "MoneyDollarCircle",
          }}
          title="Vendas a receber"
          subTitle={subTitle}
          value={value}
          width="100%"
          height="100%"
        />

        <Styles.ListContainer>
          <div className="mobile:hidden">
            <DisplayAnticipations />
          </div>

          {isMobile && (
            <>
              <Styles.FiltersRow>
                <div>
                  <Styles.Button
                    leftIcon={{
                      name: "FilterFilter",
                    }}
                    onClick={() =>
                      navigate(getReceivedRoutesData().futureReceiptsFilters)
                    }
                  >
                    Filtros
                  </Styles.Button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => exportFileDisclosure.open()}
                  >
                    <Icon name="ArrowExportRectangle" />
                  </button>
                  <button type="button" onClick={searchDisclosure.toggle}>
                    <Icon name="SearchNormal" />
                  </button>
                </div>
              </Styles.FiltersRow>

              {searchDisclosure.isOpen && (
                <Input
                  data-visible={searchDisclosure.isOpen}
                  type="search"
                  placeholder="Pesquisar"
                  value={currentState.search}
                  onChange={(e) => {
                    send({
                      type: "SET_SEARCH",
                      payload: {
                        search: e.target.value,
                      },
                    });
                  }}
                />
              )}

              <div className="mt-4 hidden mobile:block tablet:block">
                <DisplayAnticipations />
              </div>
            </>
          )}

          <Styles.List>
            <div>
              {data?.map(([month, monthInfo]) => (
                <Fragment key={month}>
                  <Styles.ListHeadings>
                    <h5>{month}</h5>
                    <p>A receber no mês {formatToBRL(monthInfo.total)}</p>
                  </Styles.ListHeadings>

                  {Object.entries(monthInfo.days)
                    .reverse()
                    .map(([day, items]) => (
                      <Styles.ListItem key={day}>
                        <button
                          type="button"
                          onClick={() => {
                            modal.open({
                              items: items.items,
                              date: day,
                              value: items.total,
                            });
                          }}
                        >
                          <Styles.ListItemInfo>
                            <div>
                              <p>Recebimento em</p>
                              <p>{day}</p>
                            </div>
                            <div>
                              <p>Total de vendas</p>
                              <p>{formatToBRL(items.total)}</p>
                            </div>
                            <div>
                              <Icon name="ArrowArrowNoLineRight" />
                            </div>
                          </Styles.ListItemInfo>
                        </button>
                      </Styles.ListItem>
                    ))}
                </Fragment>
              ))}

              {queryData?.items && (
                <div>
                  {Object.keys(data).length ? (
                    <Styles.SaleNotFound>
                      <p>
                        Não há mais nada por aqui... <br />
                        Para consultar datas anteriores use o filtro.
                      </p>
                    </Styles.SaleNotFound>
                  ) : (
                    <Styles.SaleNotFound>
                      <p>Nenhuma venda encontrada</p>
                    </Styles.SaleNotFound>
                  )}
                </div>
              )}
            </div>
          </Styles.List>
        </Styles.ListContainer>
      </Styles.Container>

      <PortabilityAndClaimsModal
        isOpen={portabilityAndClaimsModal.isOpen}
        onClose={portabilityAndClaimsModal.close}
        openConfirmAdvanceModal={confirmAdvanceModal.open}
      />
      <ConfirmAdvanceModal
        isOpen={confirmAdvanceModal.isOpen}
        onClose={confirmAdvanceModal.close}
      />
      <TermsOfUseModal
        isOpen={termsOfUseModal.isOpen}
        onClose={termsOfUseModal.close}
      />
      <Export
        isOpen={exportFileDisclosure.isOpen}
        onClose={exportFileDisclosure.close}
      />
      <DetailsModal
        content={modal.modalProps}
        isOpen={modal.isOpen}
        onClose={modal.close}
        size={{ width: 480, height: "100%" }}
      />
    </>
  );
}
