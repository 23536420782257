import Icon from "@hyperlocal/vital-icons";
import { useTheme } from "styled-components";
import { formatToBRL } from "../../../../../utils";
import { parseCardBrandIcon } from "../../../utils";
import { IDisclosureProps } from "../Disclosure";
import * as Styles from "../Disclosure.styles";

export function PositiveDisclosure({
  item,
  expanded,
  handleOpenDisclosure,
}: IDisclosureProps) {
  const { palette } = useTheme();

  function formatInstallmentsToView(
    installments: number,
    totalInstallments: number,
  ) {
    return installments <= 9
      ? `Parcela 0${installments} de 0${totalInstallments}`
      : `Parcela ${installments} de ${totalInstallments}`;
  }

  return (
    <Styles.ListItem
      key={item.guid_onlead_item}
      data-expanded={expanded}
      role="button"
      onClick={() => {
        handleOpenDisclosure(item.guid_onlead_item);
      }}
    >
      <Styles.ListItemHeader>
        <Styles.IconContainer>
          <Icon
            name={parseCardBrandIcon(item.brand_description)}
            data-state={item.brand_description.toLocaleLowerCase()}
            color={palette.primary.main}
          />
        </Styles.IconContainer>
        <div>
          <p>{item?.transaction_product}</p>
          <p>{item?.nsu}</p>
        </div>
        <div>
          <p>
            {formatInstallmentsToView(
              item?.installment_number,
              item?.total_installments,
            )}
          </p>
          <p>{formatToBRL(item?.net_value)}</p>
        </div>
        <button type="button">
          <Icon name="ArrowArrowNoLineRight" data-expanded={expanded} />
        </button>
      </Styles.ListItemHeader>
      <Styles.ListItemInfo>
        <Styles.ListItemInfoRow>
          <p>Valor da parcela</p>
          <p>{formatToBRL(item?.gross_value)}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Desconto de taxas</p>
          <p>{formatToBRL(item?.discount_value)}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Desconto de Antecipação</p>
          <p>{formatToBRL(item?.advance_value)}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Recebida em</p>
          <p>{new Date(item?.processing_date).toLocaleDateString()}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Data da Venda</p>
          <p>{new Date(item?.transaction_date).toLocaleDateString()}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Tipo da Transação</p>
          <p>{item?.transaction_product}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>N° Serial do terminal</p>
          <p>{item?.terminal_serial_number}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>NSU</p>
          <p>{item.nsu}</p>
        </Styles.ListItemInfoRow>
        {/* <Styles.ListItemInfoRow>
          <p>COD de autorização</p>
          <p>{}</p>
        </Styles.ListItemInfoRow> */}
        <div>
          <p>id da Operação</p>
          <p>{item?.guid_onlead_item}</p>
        </div>
      </Styles.ListItemInfo>
    </Styles.ListItem>
  );
}
