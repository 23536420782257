import { IModalProps, Modal } from "../../../../components";

type ITermsOfUseModalProps = Partial<IModalProps>;

export function TermsOfUseModal({ isOpen, onClose }: ITermsOfUseModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ width: 343, height: 281 }}>
      <Modal.Content>
        <p>
          Você pode antecipar todas as suas vendas ou selecionar quais quer
          antecipar.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Button variant="primary">Antecipar Todas</Modal.Button>
        <Modal.Button variant="secondary">
          Selecionar valores por dia
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}
