import { forwardRef, ForwardRefRenderFunction } from "react";
import { Skeleton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import * as Styles from "./SalesPerPeriod.styles";

const BaseSkeletonListItem: ForwardRefRenderFunction<HTMLDivElement> = (
  props,
  ref,
) => {
  return (
    <Styles.ListItem {...props} ref={ref}>
      <button type="button" disabled>
        <Styles.ListItemInfo>
          <div>
            <p>
              <Skeleton width="200px" height="21px" />
            </p>
            <p>
              <Skeleton width="200px" height="21px" />
            </p>
          </div>
          <div>
            <p>
              <Skeleton width="200px" height="21px" />
            </p>
            <p>
              <Skeleton width="200px" height="21px" />
            </p>
          </div>

          <Styles.SvgContainer>
            <Icon name="ArrowArrowNoLineRight" />
          </Styles.SvgContainer>
        </Styles.ListItemInfo>
      </button>
    </Styles.ListItem>
  );
};

export const SkeletonListItem = forwardRef(BaseSkeletonListItem);
