import React from "react";
import { IItem } from "../../../../utils";
import { NegativeDisclosure } from "./NegativeDisclosure";
import { PositiveDisclosure } from "./PositiveDisclosure";

export interface IDisclosureProps {
  item: IItem;
  expanded: boolean;
  handleOpenDisclosure: (id: string) => void;
}

export const Disclosure: React.FC<IDisclosureProps> = (props) => {
  const { item } = props;

  return (
    <div style={{ marginBottom: "16px" }}>
      {item.net_value < 0 ? (
        <NegativeDisclosure {...props} />
      ) : (
        <PositiveDisclosure {...props} />
      )}
    </div>
  );
};
