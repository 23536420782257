/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
// @ts-ignore
import { getReceivedRoutesData, setHeader } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../../../components";
import { useDisclosure } from "../../../../hooks";
import { formatTotalSalesToView, IItem } from "../../../../utils";
import { Disclosure } from "../Disclosure";
import * as Styles from "./Details.styles";

type ITransactions = {
  items: IItem[];
  date: string;
  value: number;
};

type IRouteStateData = {
  transactions: ITransactions;
};

export function Details() {
  const [disclosureId, setDisclosureId] = useState<string | null>(null);
  const expandAllDisclosure = useDisclosure();
  const location = useLocation();
  const state = location.state.data as IRouteStateData;
  const navigate = useNavigate();

  function handleOpenDisclosure(id: string) {
    if (disclosureId === id) {
      setDisclosureId(null);
    } else {
      setDisclosureId(id);
    }
  }

  function handleExpandAll() {
    if (!expandAllDisclosure.isOpen) {
      setDisclosureId(null);
      expandAllDisclosure.open();
    } else {
      expandAllDisclosure.close();
    }
  }

  useEffect(() => {
    setHeader({
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(getReceivedRoutesData().received),
      },
      title: `Recebidos de ${state.transactions.date}`,
    });
    return () => {
      setHeader({});
    };
  }, [navigate, state.transactions.date]);

  return (
    <Styles.Container>
      <Card
        avatar={{ icon: "MoneyDollarCircle" }}
        title="Total de vendas no dia"
        subTitle={formatTotalSalesToView(state.transactions.items.length)}
        value={state.transactions.value}
        width={480}
        height={84}
      />
      <Styles.Content>
        <Styles.ExpandAllButton onClick={() => handleExpandAll()}>
          <p>Expandir todos</p>
          <div>
            <Icon
              name="ArrowArrowNoLineRight"
              data-expanded={expandAllDisclosure.isOpen}
            />
          </div>
        </Styles.ExpandAllButton>
        <Styles.List>
          {state.transactions.items.map((item) => {
            const expanded =
              disclosureId === item.guid_onlead_item ||
              expandAllDisclosure.isOpen;
            return (
              <Disclosure
                key={item.guid_onlead_item}
                item={item}
                expanded={expanded}
                handleOpenDisclosure={() =>
                  handleOpenDisclosure(item.guid_onlead_item)
                }
              />
            );
          })}
        </Styles.List>
      </Styles.Content>
    </Styles.Container>
  );
}
