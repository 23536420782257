import { useAccountStore } from "@/utils";
import { anticipationKeys } from "@/utils/queryFactory";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../lib";
import { IItem } from "./useSales";

export type Item = IItem;

export interface CalendarItem {
  advance_value: number;
  discount_value: number;
  gross_value: number;
  net_value: number;
  items: Item[];
}

export type GetCalendarItemParams = {
  guidAccount: string;
  startDate: string;
  endDate: string;
  status: "SCHEDULED";
};

export const generateCalendarItem = (): CalendarItem => {
  const items = [
    {
      guid_onlead_item: "uuid1",
      transaction_date: "2023-01-01T00:00:00.000Z",
      processing_date: "2023-06-01T00:00:00.000Z",
      status: "SCHEDULED",
      gross_value: 100.0,
      discount_value: 10.0,
      advance_value: 20.0,
      net_value: 70.0,
      brand_description: "Brand A",
      transaction_product: "Product A",
      installment_number: 1,
      total_installments: 12,
      nsu: "nsu1",
      terminal_serial_number: "123456",
      type: "credit",
      bank_address: null,
    },
    {
      guid_onlead_item: "uuid2",
      transaction_date: "2023-02-01T00:00:00.000Z",
      processing_date: "2023-07-01T00:00:00.000Z",
      status: "SCHEDULED",
      gross_value: 200.0,
      discount_value: 20.0,
      advance_value: 40.0,
      net_value: 140.0,
      brand_description: "Brand B",
      transaction_product: "Product B",
      installment_number: 2,
      total_installments: 12,
      nsu: "nsu2",
      terminal_serial_number: "654321",
      type: "debit",
      bank_address: null,
    },
    {
      guid_onlead_item: "uuid3",
      transaction_date: "2023-03-01T00:00:00.000Z",
      processing_date: "2023-08-01T00:00:00.000Z",
      status: "SCHEDULED",
      gross_value: 300.0,
      discount_value: 30.0,
      advance_value: 60.0,
      net_value: 210.0,
      brand_description: "Brand C",
      transaction_product: "Product C",
      installment_number: 3,
      total_installments: 12,
      nsu: "nsu3",
      terminal_serial_number: "789012",
      type: "voucher",
      bank_address: null,
    },
  ];

  const sumValues = (key) => items.reduce((acc, item) => acc + item[key], 0);

  return {
    advance_value: sumValues("advance_value"),
    discount_value: sumValues("discount_value"),
    gross_value: sumValues("gross_value"),
    net_value: sumValues("net_value"),
    items,
  };
};

export const getCalendar = async ({
  guidAccount,
  startDate,
  endDate,
}: GetCalendarItemParams) => {
  try {
    const { data } = await api.get<CalendarItem>(
      `Sales/${guidAccount}/Receipts/Items/date?startDate=${startDate}&endDate=${endDate}`,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const queryCalendarItem = ({
  guidAccount,
  startDate,
  endDate,
  status,
}: GetCalendarItemParams) => {
  return {
    queryKey: anticipationKeys.getCalendarItem({
      guidAccount,
      endDate,
      startDate,
    }),
    queryFn: () => getCalendar({ guidAccount, startDate, endDate, status }),
  };
};

export const useCalendarItem = (
  params: Omit<GetCalendarItemParams, "guidAccount">,
) => {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    ...queryCalendarItem({
      guidAccount: currentAccountId,
      ...params,
    }),
    suspense: false,
  });
};
