import styled from "styled-components";

export const Container = styled.svg({
  animation: "rotate 1s linear infinite",
  margin: "40px",
  width: "50px",
  height: "50px",

  "& .path": {
    stroke: "#5652bf",
    strokeLinecap: "round",
    animation: "dash 1.5s ease-in-out infinite",
  },

  "@keyframes rotate": {
    "100%": {
      transform: "rotate(360deg)",
    },
  },

  "@keyframes dash": {
    "0%": {
      strokeDasharray: "1, 150",
      strokeDashoffset: "0",
    },
    "50%": {
      strokeDasharray: "90, 150",
      strokeDashoffset: "-35",
    },
    "100%": {
      strokeDasharray: "90, 150",
      strokeDashoffset: "-124",
    },
  },
});
