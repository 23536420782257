import { Suspense } from "react";
import { getVertical, Notifications, themeProvider } from "@/utils";
import { Provider } from "@hyperlocal/vital";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ErrorFallback, LoadingFallback } from "../components";
import { NODE_ENV } from "../config";
import { queryClient } from "../lib";
import { Routes } from "../routes";
import { GlobalStyles } from "../styles";

export function App() {
  const theme = getVertical();

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <Suspense fallback={<LoadingFallback />}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <QueryClientProvider client={queryClient}>
                {NODE_ENV === "development" && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
                <GlobalStyles />
                <Routes />
              </QueryClientProvider>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
        <Notifications />
      </ThemeProvider>
    </Provider>
  );
}
