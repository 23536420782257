import { useRef } from "react";
import { Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { Card, IModalProps } from "../../../../components";
import { useDisclosure } from "../../../../hooks";
import { formatTotalSalesToView } from "../../../../utils";
import { IItem } from "../../api";
import { Disclosure } from "../Disclosure";
import * as Styles from "./DetailsModal.styles";

type IDetailsModalProps = Partial<IModalProps> & {
  content: {
    date: string;
    value: number;
    items: IItem[];
  };
};

export function DetailsModal({ isOpen, onClose, content }: IDetailsModalProps) {
  const { disclosures, expandAll, toggleDisclosures } = useDisclosure();
  const drawerTopRef = useRef(null);

  const handleBackToTop = () => {
    if (!drawerTopRef.current) {
      return null;
    }

    return drawerTopRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = () => {
    expandAll([]);
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      onDismiss={handleClose}
      title={`Recebimento em ${content.date}`}
      position="right"
    >
      <div ref={drawerTopRef} />
      <Card
        avatar={{ icon: "MoneyDollarCircle" }}
        title="Total a receber no dia"
        subTitle={formatTotalSalesToView(content?.items?.length)}
        value={content?.value}
        width={432}
        minWidth={432}
        height={84}
        padding="0px"
      />
      <Styles.Content>
        <Styles.ExpandAllButton onClick={() => expandAll(content?.items)}>
          <p>Expandir todos</p>
          <div>
            <Icon
              name="ArrowArrowNoLineRight"
              data-expanded={disclosures.length === content?.items?.length}
            />
          </div>
        </Styles.ExpandAllButton>
        <Styles.ListContainer>
          <Styles.List>
            {content?.items &&
              content.items.map((item, index) => {
                const id = index.toString();
                const expanded = disclosures.includes(id);
                return (
                  <Disclosure
                    key={item.guid_onlead_item}
                    item={item}
                    expanded={expanded}
                    handleOpenDisclosure={() => toggleDisclosures(id)}
                  />
                );
              })}
            {(disclosures.length > 1 || content?.items?.length > 5) && (
              <Styles.GoToTopButton
                className="button"
                icon="ArrowArrowNoLineUp"
                variant="secondary"
                onClick={handleBackToTop}
              >
                TOPO
              </Styles.GoToTopButton>
            )}
          </Styles.List>
        </Styles.ListContainer>
      </Styles.Content>
    </Drawer>
  );
}
