import { subDays } from "date-fns";
import { create } from "zustand";
import { redux } from "zustand/middleware";
import { FILTERS } from "../../../config";

type IFilters = (typeof FILTERS)[number];

interface IState {
  startDate: Date;
  endDate: Date;
  search: string;
  filters: IFilters[];
  selectedFilter: IFilters;
  dateErrors: {
    startDate?: string;
    endDate?: string;
  };
}

type IActionType =
  | { type: "SET_START_DATE"; payload: { startDate: Date } }
  | { type: "SET_END_DATE"; payload: { endDate: Date } }
  | {
      type: "SET_START_AND_END_DATE";
      payload: { startDate: Date | string; endDate: Date | string };
    }
  | { type: "SET_SEARCH"; payload: { search: string } }
  | { type: "SET_SELECTED_FILTER"; payload: { selectedFilter: IFilters } }
  | {
      type: "SET_DATE_ERRORS";
      payload: { dateErrors: { startDate?: string; endDate?: string } };
    }
  | { type: "CLEAR_FILTERS" };

const TODAY = new Date();

function initialStartDate() {
  const date = new Date(TODAY);
  date.setHours(0, 0, 0, 0);
  return date;
}

function initialEndDate() {
  const date = new Date(TODAY);
  return date;
}

function setStartDate(state: IState, startDate: Date | string): IState {
  if (!state.startDate) return state;

  const newStartDate = new Date(startDate);
  newStartDate.setHours(0, 0, 0, 0);
  newStartDate.setDate(newStartDate.getDate() + 1);

  const ninetyDaysBeforeEndDate = new Date(state.endDate);
  ninetyDaysBeforeEndDate.setHours(0, 0, 0, 0);
  ninetyDaysBeforeEndDate.setDate(state.endDate.getDate() - 90);

  if (newStartDate < ninetyDaysBeforeEndDate) {
    return {
      ...state,
      dateErrors: {
        ...state.dateErrors,
        startDate: "Período máximo de 90 dias.",
      },
    };
  }

  if (newStartDate > state.endDate) {
    return {
      ...state,
      dateErrors: {
        ...state.dateErrors,
        startDate: "A data de início deve ser anterior à data de término",
      },
    };
  }

  return {
    ...state,
    startDate: newStartDate,
    dateErrors: { ...state.dateErrors, startDate: undefined },
  };
}

function setEndDate(state: IState, endDate: Date | string) {
  if (!state.endDate) return state;

  const newEndDate = new Date(endDate);
  newEndDate.setHours(0, 0, 0, 0);
  newEndDate.setDate(newEndDate.getDate() + 1);

  const ninetyDaysAfterStartDate = new Date(state.startDate);
  ninetyDaysAfterStartDate.setHours(0, 0, 0, 0);
  ninetyDaysAfterStartDate.setDate(state.startDate.getDate() + 90);

  if (newEndDate > ninetyDaysAfterStartDate) {
    return {
      ...state,
      dateErrors: {
        ...state.dateErrors,
        endDate: "Período máximo de 90 dias.",
      },
    };
  }

  if (newEndDate < state.startDate) {
    return {
      ...state,
      dateErrors: {
        ...state.dateErrors,
        endDate: "A data de término deve ser posterior à data de início",
      },
    };
  }

  return {
    ...state,
    endDate: newEndDate,
    dateErrors: { ...state.dateErrors, endDate: undefined },
  };
}

function setStartAndEndDate(
  state: IState,
  startDate: Date | string,
  endDate: Date | string,
) {
  return {
    ...state,
    startDate: setStartDate(state, startDate).startDate,
    endDate: setEndDate(state, endDate).endDate,
  };
}

function setSearch(state: IState, search: string) {
  return { ...state, search: search.toLowerCase() };
}

function setSelectedFilter(state: IState, selectedFilter: IFilters) {
  if (state.dateErrors.startDate || state.dateErrors.endDate) {
    return {
      ...state,
      dateErrors: {
        ...state.dateErrors,
        startDate: undefined,
        endDate: undefined,
      },
    };
  }

  const currentDate = new Date(TODAY);
  const newDate = new Date(TODAY).getDate() - Number(selectedFilter);
  currentDate.setDate(newDate);
  if (currentDate !== state.startDate) {
    return {
      ...state,
      selectedFilter,
      startDate: currentDate,
      endDate: new Date(TODAY),
    };
  }

  return {
    ...state,
    selectedFilter,
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  };
}

function setDateErrors(
  state: IState,
  dateErrors: { startDate?: string; endDate?: string },
) {
  return { ...state, dateErrors };
}

export const initialState: IState = {
  startDate: subDays(TODAY, 7),
  endDate: TODAY,
  search: "",
  filters: [...FILTERS],
  selectedFilter: undefined,
  dateErrors: {
    startDate: "",
    endDate: "",
  },
};

function reducer(state: IState, action: IActionType): IState {
  switch (action.type) {
    case "SET_START_DATE":
      return setStartDate(state, action.payload.startDate);
    case "SET_END_DATE":
      return setEndDate(state, action.payload.endDate);
    case "SET_START_AND_END_DATE":
      return setStartAndEndDate(
        state,
        action.payload.startDate,
        action.payload.endDate,
      );
    case "SET_SEARCH":
      return setSearch(state, action.payload.search);
    case "SET_SELECTED_FILTER":
      return setSelectedFilter(state, action.payload.selectedFilter);
    case "SET_DATE_ERRORS":
      return setDateErrors(state, action.payload.dateErrors);
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

const useStore = create(redux(reducer, initialState));

export function useFilters() {
  const currentState = useStore((state) => state);
  const dispatch = useStore((state) => state.dispatch);
  return [currentState, dispatch] as const;
}
