import { useEffect, useMemo, useState } from "react";
import { getReceivedRoutesData, Hooks, setHeader } from "@/utils";
import { Input } from "@hyperlocal/vital";
import { isSameDay } from "date-fns";
import { useNavigate } from "react-router-dom";
import { formatToAPI } from "../../../../utils/date/formatToAPI";
import { formatToUI } from "../../../../utils/date/formatToUI";
import { IItem, useGetReceivables } from "../../api";
import { useCalendarItem } from "../../api/useCalendarItem";
import { AnticipationConfirmation } from "../AnticipationConfirmation";
import {
  CalendarDrawerList,
  CalendarDrawerListSkeleton,
} from "../CalendarDrawerList";
import { CalendarDrawerResume } from "../CalendarDrawerResume";
import * as S from "./styles";

type CalendarDrawerProps = {
  onClose?: () => void;
  start: Date;
  end: Date;
};

const getDate = (date: Date) => {
  return new Date(date.setHours(0, 0, 0, 0));
};

export const CalendarDrawer = (props: CalendarDrawerProps) => {
  const { onClose, end, start } = props;

  const { data, isLoading } = useCalendarItem({
    status: "SCHEDULED",
    startDate: formatToAPI(start),
    endDate: formatToAPI(end),
  });

  const { data: receivablesData } = useGetReceivables();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();

  const filteredItems = data?.items.filter((item: IItem) => {
    const searchString = searchQuery.toLowerCase();

    return (
      item.guid_onlead_item?.toLowerCase().includes(searchString) ||
      item.nsu?.toLowerCase().includes(searchString) ||
      item.brand_description?.toLowerCase().includes(searchString) ||
      item.transaction_product?.toLowerCase().includes(searchString)
      // Adicione outros campos que você deseja pesquisar aqui
    );
  });

  const date = useMemo(() => {
    const [startDate, endDate] = [formatToUI(start), formatToUI(end)];

    const isSameDay = startDate === endDate;

    return isSameDay ? startDate : `${startDate} - ${endDate}`;
  }, [end, start]);

  const groupedItems = useMemo(() => {
    const groupedMap = new Map();

    filteredItems?.forEach((item) => {
      const processingDate = String(item.processing_date).split("T")[0];

      if (groupedMap.has(processingDate)) {
        groupedMap.get(processingDate).push(item);
      } else {
        groupedMap.set(processingDate, [item]);
      }
    });

    const groupedArray = Array.from(groupedMap, ([date, items]) => ({
      date,
      items,
    }));

    return groupedArray;
  }, [filteredItems]);

  const datesToAnticipate = data?.items.reduce((prev, cur) => {
    if (cur.status !== "SCHEDULED") return prev;

    prev.push(getDate(new Date(cur.processing_date)));

    return prev;
  }, []);

  const nsuList = datesToAnticipate?.reduce<string[]>((list, currentDate) => {
    const receivableDate = receivablesData?.receivableAgenda.find(
      (receivable) =>
        isSameDay(getDate(new Date(receivable.date)), currentDate),
    );

    if (!receivableDate) return list;

    receivableDate.receivables.forEach((receivable) =>
      list.push(receivable.instalmentNsu),
    );

    return [...new Set(list)];
  }, []);

  useEffect(() => {
    isMobile &&
      setHeader({
        title: date,
        leftIcon: {
          name: "GeralClose",
          onClick: () =>
            navigate(getReceivedRoutesData().futureReceiptsCalendar),
        },
      });
    return () => {
      setHeader({});
    };
  }, [date, navigate, isMobile]);

  return (
    <>
      <S.Container>
        {onClose && (
          <S.Header>
            <S.Date>{date}</S.Date>
          </S.Header>
        )}

        <CalendarDrawerResume item={data} loading={isLoading} />

        <Input
          placeholder="Buscar por nº do pedido, CNPJ, cartão..."
          type="search"
          style={{ boxSizing: "content-box" }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {isLoading ? (
          <CalendarDrawerListSkeleton />
        ) : (
          groupedItems
            .reverse()
            .map((group) => (
              <CalendarDrawerList
                items={group.items}
                loading={isLoading}
                date={group.date}
                key={group.date.toString()}
              />
            ))
        )}

        <AnticipationConfirmation
          nsuList={nsuList}
          isDisabled={!nsuList?.length}
        />
      </S.Container>
    </>
  );
};
