import { Button, Skeleton } from "@hyperlocal/vital";
import { IAvatarProps } from "@hyperlocal/vital/dist/umd/components/Avatar/types";
import { formatToBRL } from "../../utils";
import * as Styles from "./Card.styles";

export interface ICardProps {
  avatar: IAvatarProps;
  title: string;
  subTitle: string;
  value: number;
  width?: string | number;
  padding?: string | number;
  height?: string | number;
  minWidth?: string | number;
  action?: {
    label: string;
    onClick: () => void;
  };
  isloading?: boolean;
}

export function Card({
  avatar,
  title,
  subTitle,
  value,
  height,
  width,
  action,
  padding,
  minWidth,
  isloading = false,
}: ICardProps) {
  const formattedValue = formatToBRL(value ?? 0);
  const [currency, valueWithoutCurrency] = formattedValue.split(/\u00a0/);
  return (
    <Styles.Container
      width={width}
      height={height}
      padding={padding}
      minW={minWidth}
    >
      <Styles.Avatar
        icon={avatar.icon}
        variant={avatar.variant}
        size={avatar.size}
      />
      <Styles.Content>
        {isloading ? (
          <>
            <Styles.Info>
              <p>
                <Skeleton width="125px" height="24px" />
              </p>
              <span>
                {" "}
                <Skeleton width="79px" height="21px" />
              </span>
            </Styles.Info>
            <Styles.Value>
              <h6>
                {" "}
                <Skeleton />
              </h6>{" "}
              <h4>
                {" "}
                <Skeleton width="170px" />
              </h4>
            </Styles.Value>
          </>
        ) : (
          <>
            <Styles.Info>
              <p>{title}</p>
              <span>{subTitle}</span>
            </Styles.Info>
            <Styles.Value>
              <h6>{currency}</h6> <h4>{valueWithoutCurrency}</h4>
            </Styles.Value>
          </>
        )}
        {action && (
          <Button variant="link" type="button" onClick={() => action.onClick()}>
            {action?.label}
          </Button>
        )}
      </Styles.Content>
    </Styles.Container>
  );
}
