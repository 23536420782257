import { CalendarItem } from "@/features/futureReceipts/api/useCalendarItem";
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import { CalendarTableRow } from "./Row";
import { CalendarTableSkeleton } from "./Skeleton";
import * as S from "./styles";

export type CalendarTableProps = {
  data: CalendarItem;
  date: Date;
  loading: boolean;
  selectionMode: "SINGLE" | "RANGE";
  onSelectRange: (range: { start: Date; end: Date }) => void;
  selectedRange: { start: Date; end: Date } | null;
};

export type TableRowsData = {
  totalAmount: number;
  totalItems: number;
};

type GroupedTable = Record<PropertyKey, TableRowsData>;

const formatDateToIndex = (date: Date) => format(new Date(date), "dd/MM/yyyy");

const groupTableData = (calendarData: CalendarItem) => {
  return calendarData?.items.reduce<GroupedTable>((acc, cur) => {
    const processingDate = formatDateToIndex(new Date(cur.processing_date));

    if (!acc[processingDate])
      acc[processingDate] = {
        totalAmount: 0,
        totalItems: 0,
      };

    acc[processingDate].totalAmount += cur.net_value;
    acc[processingDate].totalItems += 1;

    return acc;
  }, {});
};

const CalendarTable = (props: CalendarTableProps) => {
  const { data, date, loading, ...selectionOptions } = props;

  if (loading) return <CalendarTableSkeleton />;

  const generateCalendarDates = () => {
    const firstDayOfMonth = startOfMonth(date);
    const lastDayOfMonth = endOfMonth(date);

    // Dois últimos dias do mês anterior
    const startOfPreviousMonth = subMonths(firstDayOfMonth, 1);
    const lastDayOfPreviousMonth = endOfMonth(startOfPreviousMonth);
    const daysOfPreviousMonth = eachDayOfInterval({
      start: addDays(lastDayOfPreviousMonth, -1),
      end: lastDayOfPreviousMonth,
    });

    // Dois primeiros dias do mês seguinte
    const startOfNextMonth = addDays(lastDayOfMonth, 1);
    const daysOfNextMonth = eachDayOfInterval({
      start: startOfNextMonth,
      end: addDays(startOfNextMonth, 1),
    });

    const daysOfMonth = eachDayOfInterval({
      start: firstDayOfMonth,
      end: lastDayOfMonth,
    });

    return [...daysOfPreviousMonth, ...daysOfMonth, ...daysOfNextMonth];
  };

  const calendarDates = generateCalendarDates();

  const tableData = groupTableData(data);

  return (
    <S.TableContainer className="calendar-table">
      <S.Table>
        <thead>
          <tr>
            <S.Th>Dias</S.Th>
            <S.Th>Recebimentos</S.Th>
            <S.Th>Valores do Dia</S.Th>
            <S.Th></S.Th>
          </tr>
        </thead>

        <tbody>
          {calendarDates?.map((date) => {
            const defaultData = {
              totalAmount: 0,
              totalItems: 0,
            };

            const itemsOnDate = tableData
              ? tableData[formatDateToIndex(new Date(date))]
              : defaultData;

            const rowData: TableRowsData = itemsOnDate || {
              totalAmount: 0,
              totalItems: 0,
            };

            return (
              <CalendarTableRow
                key={date.toString()}
                date={date}
                isDisabled={!isSameMonth(date, props.date)}
                isLoading={loading}
                rowInfo={rowData}
                {...selectionOptions}
              />
            );
          })}
        </tbody>
      </S.Table>
    </S.TableContainer>
  );
};

export default CalendarTable;
