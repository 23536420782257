/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect } from "react";
import {
  getSalesRoutesData,
  Hooks,
  setHeader,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { navigateToUrl } from "single-spa";
import { Layout } from "../../../components";
import { useDisclosure } from "../../../hooks";
import { Export, Filters, SalesPerPeriod } from "../components";
import * as Styles from "./ReceivedValues.styles";

export function ReceivedValues() {
  const exportFilesDrawer = useDisclosure();
  const isDesktop = Hooks.useMediaQuery("desktop");

  const backToDashboard = useCallback(() => {
    navigateToUrl(getSalesRoutesData().dashboard);
  }, []);

  useEffect(() => {
    setHeader({
      title: "Valores Recebidos",
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToDashboard,
      },
    });
    return () => {
      setHeader({});
    };
  }, [backToDashboard, exportFilesDrawer, exportFilesDrawer.open, isDesktop]);

  return (
    <>
      <Layout
        title="Valores Recebidos"
        breadcrumb={["Valores Recebidos"]}
        onBreadcrumbClick={backToDashboard}
      >
        <Styles.Container>
          {isDesktop ? <Filters /> : null}
          <SalesPerPeriod />
        </Styles.Container>
      </Layout>
      <Export
        isOpen={exportFilesDrawer.isOpen}
        onClose={exportFilesDrawer.close}
      />
    </>
  );
}
