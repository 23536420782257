import styled from "styled-components";
import { EBreakPoints } from "../../../@types";

export const Wrapper = styled.div(({ theme: { palette } }) => ({
  display: "flex",
  width: "100%",

  background: palette.neutral.lighter,

  "> div": {
    width: "100%",

    maxWidth: "1248px",
    margin: "0 auto",
  },

  ".wrapperIcon": {
    display: "none !important", // Remoção do "header" padrão do Drawer do DS
  },
}));

export const Container = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacings.stack.x3s,

  flex: 1,

  width: "100%",

  position: "relative",
  overflow: "hidden",

  [EBreakPoints.mobile]: {
    padding: spacings.inset.md,
  },
}));

export const CalendarContainer = styled.div(
  ({ theme: { palette, radii, spacings } }) => ({
    width: "100%",
    background: palette.neutral.white,
    borderRadius: radii.sm,
    overflow: "hidden",
    padding: spacings.inset.lg,

    minHeight: "704px",

    display: "flex",
    flexDirection: "column",
    gap: spacings.inline.sm,

    ".calendar-table": {
      display: "none",
    },

    [EBreakPoints.mobile]: {
      padding: 0,

      ".calendar-table": {
        display: "block",
      },

      ".rbc-wrapper": {
        display: "none",
      },
    },
  }),
);
