import { ComponentProps, useEffect, useState } from "react";
import {
  formatCurrency,
  getReceivedRoutesData,
  Hooks,
  setHeader,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { TextField } from "@hyperlocal/vital2";
import { Accordion } from "@radix-ui/react-accordion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAnticipationDetails } from "../../api/useAnticipationDetails";
import { AnticipationDetailsItem } from "./details-item";
import { AnticipationListCardSkeleton } from "./list-card";

export const AnticipationDetails = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  const [searchParams] = useSearchParams();
  const guid = searchParams.get("guid");

  const isMobile = Hooks.useMediaQuery("mobile");
  const routes = getReceivedRoutesData();
  const navigate = useNavigate();

  const { data, isLoading } = useAnticipationDetails(guid);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    isMobile &&
      setHeader({
        title: "27/03/24 - 31/03/24",
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: () => navigate(routes.futureReceiptsAnticipation),
        },
      });
    return () => {
      setHeader({});
    };
  }, [isMobile, navigate, routes]);

  if (isLoading)
    return <AnticipationDetailsSkeleton className={className} {...props} />;

  const filteredReceivables = data?.spotRequestReceivables?.filter(
    (receivable) => {
      return (
        receivable.nsu.includes(searchQuery) ||
        receivable.guidSpotRequests.includes(searchQuery) ||
        receivable.installmentNsu.includes(searchQuery)
      );
    },
  );

  return (
    <div className={twMerge(className)} {...props}>
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center gap-2">
          <div className="flex size-[40px] items-center justify-center rounded-full bg-primary-lighter">
            <Icon
              name="MoneyAntecipate"
              className="size-[22px] fill-primary-dark"
            />
          </div>

          <div>
            <h5 className="text-x2s font-medium text-neutral-darkest mobile:text-x4s">
              valor antecipado
            </h5>

            <p className="text-neutral-dark mobile:text-x2s">
              {data?.spotRequestReceivables?.length} vendas
            </p>
          </div>
        </div>

        <p className="text-md font-bold text-primary-main">
          <span className="text-xs">R$</span>{" "}
          {formatCurrency(
            data?.spotAnticipationRequest?.prepaymentGrossAmountRequested,
          ).replace("R$", "")}
        </p>
      </div>

      <div className="space-y-2 border-t py-4">
        <div className="grid grid-cols-2 gap-2">
          <span className="font-medium">Desconto de taxas</span>
          <span className="text-right text-status-error-dark">
            -
            {formatCurrency(
              data?.spotAnticipationRequest?.prepaymentGrossAmountRequested -
                data?.spotAnticipationRequest?.prepaymentNetAmountRequested,
            )}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <span className="font-medium">Valor total a receber</span>

          <span className="text-right text-status-error-dark">
            <p className="text-md font-bold text-primary-main">
              <span className="text-xs">R$</span>{" "}
              {formatCurrency(
                data?.spotAnticipationRequest?.prepaymentNetAmountRequested,
              ).replace("R$", "")}
            </p>
          </span>
        </div>
      </div>

      <TextField.Root>
        <TextField.Input
          placeholder="Buscar por nº do pedido, CNPJ, cartão..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <TextField.Slot name="SearchNormal" />
      </TextField.Root>

      <Accordion type="multiple" className="space-y-2 py-4">
        {filteredReceivables?.map((receivable) => {
          return (
            <AnticipationDetailsItem
              receivable={receivable}
              spotAnticipationRequest={data?.spotAnticipationRequest}
              key={receivable.installmentNsu}
            />
          );
        })}
      </Accordion>
    </div>
  );
};

export const AnticipationDetailsSkeleton = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  return (
    <div className={twMerge(className)} {...props}>
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center gap-2">
          <div className="size-[40px] animate-pulse rounded-full bg-neutral-lighter" />

          <div className="space-y-1">
            <div className="h-[1ex] w-[10ch] animate-pulse rounded-lg bg-neutral-lighter" />

            <div className="h-[1.5ex] w-[7ch] animate-pulse rounded-lg bg-neutral-lighter" />
          </div>
        </div>

        <div className="h-[3ex] w-[10ch] animate-pulse rounded-lg bg-neutral-lighter" />
      </div>

      <div className="space-y-2 border-t py-4">
        <div className="flex justify-between">
          <div className="h-[2ex] w-[10ch] animate-pulse rounded-lg bg-neutral-lighter" />

          <div className="h-[2ex] w-[5ch] animate-pulse rounded-lg bg-neutral-lighter" />
        </div>

        <div className="flex justify-between">
          <div className="h-[2ex] w-[10ch] animate-pulse rounded-lg bg-neutral-lighter" />

          <div className="h-[2ex] w-[5ch] animate-pulse rounded-lg bg-neutral-lighter" />
        </div>
      </div>

      <TextField.Root>
        <TextField.Input
          placeholder="Buscar por nº do pedido, CNPJ, cartão..."
          disabled
        />

        <TextField.Slot name="SearchNormal" />
      </TextField.Root>

      <div className="space-y-2 py-4">
        {Array.from({ length: 5 }).map((_, index) => {
          return <AnticipationListCardSkeleton key={index} />;
        })}
      </div>
    </div>
  );
};
