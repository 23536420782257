import { API, useAccountStore } from "@/utils";
import { anticipationKeys } from "@/utils/queryFactory";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

// import { anticipation } from "./_mock/anticipation";

export type AnticipationStatus = "PENDING" | "APPROVED" | "REJECTED" | "PAID";
export type AnticipationRequest = {
  guidSpotRequests: string;
  guidCustomer: string;
  anticipationExecuteDate: string | null;
  internalApprovalDate: string;
  internalApprovalOcurrence: string;
  spotRequestType: string;
  cardBrand: string;
  status: AnticipationStatus;
  prepaymentGrossAmountRequested: number;
  prepaymentNetAmountRequested: number;
  receivablesCountRequested: number;
  prepaymentGrossAmountPaid: number;
  prepaymentNetAmountPaid: number;
  paymentDate: string;
  receivablesCountPaid: number;
  fee: number;
  createdAt: string;
  updatedAt: string;
  anticipationOcurrenceMessage: string;
};

type Response = {
  spotAnticipationRequest: AnticipationRequest[];
};

export type GetAnticipationParams = {
  guidCustomer: string;
  initialDate: string;
  endDate: string;
  status: AnticipationStatus;
};

const getAnticipation = async ({
  guidCustomer,
  initialDate,
  endDate,
  status,
}: GetAnticipationParams) => {
  const anticipation = await API.spot.get<Response>(
    "/api/SpotReceivables/ListAnticipationsRequests",
    {
      params: {
        guidCustomer,
        page: 1,
        limit: 999,
        initialDate,
        endDate,
        status,
      },
    },
  );

  return anticipation.data;
};

const query = (params: GetAnticipationParams) => {
  return {
    queryKey: anticipationKeys.getAnticipation(params),
    queryFn: () => getAnticipation(params),
  };
};

export const useAnticipation = () => {
  const [searchParams] = useSearchParams();
  const { account } = useAccountStore();
  const guidCustomer =
    account.personInfoResponse?.customerId ||
    account.companyInfoResponse.customerId;

  return useQuery({
    ...query({
      guidCustomer,
      endDate: searchParams.get("endDate"),
      initialDate: searchParams.get("initialDate"),
      status: searchParams.get("status") as AnticipationStatus,
    }),
    suspense: false,
  });
};
