import { useMemo } from "react";
import { adjustForTimezone } from "../../../utils/date/adjustForTimezone";
import { CalendarDrawer } from "../components/CalendarDrawer";

export const CalendarDetailsRoute = () => {
  const queryParams = new URLSearchParams(location.search);
  const startParam = queryParams.get("start");
  const endParam = queryParams.get("end");

  const start = useMemo(
    () => (startParam ? adjustForTimezone(new Date(startParam)) : new Date()),
    [startParam],
  );

  const end = useMemo(
    () => (endParam ? adjustForTimezone(new Date(endParam)) : new Date()),
    [endParam],
  );

  return <CalendarDrawer start={start} end={end} />;
};
