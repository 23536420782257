import { RadioGroup, TextField } from "@hyperlocal/vital2";
import { addDays, differenceInDays, format } from "date-fns";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Range = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialDate = searchParams.get("initialDate");
  const endDate = searchParams.get("endDate");

  const handleUpdateRange = (daysAmount: number) => {
    const newInitialDate = format(new Date(), "yyyy-MM-dd");
    const newEndDate = format(addDays(new Date(), daysAmount), "yyyy-MM-dd");

    setSearchParams((params) => {
      params.set("initialDate", newInitialDate);
      params.set("endDate", newEndDate);

      return params;
    });
  };

  return (
    <div className="flex gap-2">
      {[7, 15, 30].map((daysAmount) => {
        const difference = differenceInDays(
          new Date(endDate),
          new Date(initialDate)
        );

        const isActive = difference === daysAmount;

        return (
          <div
            className={twMerge(
              "font-medium rounded-sm border text-primary-main border-primary-main px-2 py-1 cursor-pointer",
              isActive && "text-white bg-primary-main"
            )}
            key={daysAmount}
            onClick={() => handleUpdateRange(daysAmount)}
          >
            {daysAmount} dias
          </div>
        );
      })}
    </div>
  );
};

const Dates = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialDate = searchParams.get("initialDate");
  const endDate = searchParams.get("endDate");

  const handleUpdateDate = (key: "initialDate" | "endDate", date: string) => {
    setSearchParams((params) => {
      params.set(key, date);
      return params;
    });
  };

  return (
    <div className="space-y-4">
      <h4 className="font-bold text-sm">Período personalizado</h4>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <TextField.Label htmlFor="initialDate">De</TextField.Label>
          <TextField.Root>
            <TextField.Input
              name="initialDate"
              type="date"
              value={initialDate}
              min={format(new Date(), "yyyy-MM-dd")}
              onChange={({ target: { value } }) =>
                handleUpdateDate("initialDate", value)
              }
            />
            <TextField.Slot name="GeralCalendar" />
          </TextField.Root>
        </div>

        <div>
          <TextField.Label htmlFor="endDate">Até</TextField.Label>
          <TextField.Root>
            <TextField.Input
              placeholder="23/03/2022"
              name="endDate"
              type="date"
              value={endDate}
              min={format(new Date(), "yyyy-MM-dd")}
              max={format(addDays(new Date(), 30), "yyyy-MM-dd")}
              onChange={({ target: { value } }) =>
                handleUpdateDate("endDate", value)
              }
            />
            <TextField.Slot name="GeralCalendar" />
          </TextField.Root>
        </div>
      </div>
    </div>
  );
};

type AnticipationStatus = "PAID" | "PENDING" | "REJECTED";
const status: Array<{ label: string; value: AnticipationStatus }> = [
  {
    label: "Pagas",
    value: "PAID",
  },
  {
    label: "Pendentes",
    value: "PENDING",
  },
  {
    label: "Canceladas",
    value: "REJECTED",
  },
];

const Status = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleUpdateStatus = (status: AnticipationStatus) => {
    setSearchParams((params) => {
      params.set("status", status);
      return params;
    });
  };

  return (
    <div className="space-y-4">
      <h4 className="font-bold text-sm">Status das antecipações</h4>

      <RadioGroup.Root
        className="grid grid-cols-3 gap-4 mobile:grid-cols-2"
        onValueChange={handleUpdateStatus}
        defaultValue={searchParams.get("status") ?? ""}
      >
        {status.map(({ value, label }) => {
          return (
            <RadioGroup.Items className="my-inset-x2s p-0 flex" key={value}>
              <RadioGroup.Item
                id={value}
                value={value}
                className="radio-button-acquiring-received-wildcard"
              />

              <RadioGroup.Label htmlFor={value}>{label}</RadioGroup.Label>
            </RadioGroup.Items>
          );
        })}
      </RadioGroup.Root>
    </div>
  );
};

export const AnticipationFiltersFields = { Range, Dates, Status };
