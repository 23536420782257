import React from "react";
import { Spinner } from "../Spinner";
import { Container } from "./LoadingFallback.styles";

type ILoadingFallbackProps = React.HTMLAttributes<HTMLDivElement>;

export function LoadingFallback({ ...rest }: ILoadingFallbackProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Container
        role="progressbar"
        aria-valuetext="Carregando..."
        aria-busy="true"
        aria-live="assertive"
        aria-valuemin={0}
        aria-valuemax={100}
        {...rest}
      >
        <h2>Carregando...</h2>
        <Spinner />
      </Container>
    </div>
  );
}
