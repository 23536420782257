/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getReceivedRoutesData } from "@hyperlocal/banking-utility";
import { Route, Routes as Switch } from "react-router-dom";
import { Anticipation } from "../components/Anticipation";
import { AnticipationDetails } from "../components/Anticipation/details";
import { AnticipationFilters } from "../components/Anticipation/filters";
import { AnticipationReceipt } from "../components/AnticipationReceipt";
import { CalendarRoute } from "./Calendar";
import { CalendarDetailsRoute } from "./CalendarDetails";
import { Filters } from "./Filters";
import { Root } from "./Root";

const routes = getReceivedRoutesData();

export const FutureReceiptsRoutes = () => {
  return (
    <Switch>
      <Route path={routes.futureReceipts} element={<Root />} />
      <Route path={routes.futureReceiptsFilters} element={<Filters />} />

      {/* Calendar routes */}
      <Route path={routes.futureReceiptsCalendar} element={<CalendarRoute />} />
      <Route
        path={routes.futureReceiptsCalendarDetails}
        element={<CalendarDetailsRoute />}
      />

      {/* Anticipation routes */}
      <Route
        path={routes.futureReceiptsAnticipation}
        element={<Anticipation />}
      />
      <Route
        path="/received/future-receipts/anticipation/details"
        element={<AnticipationDetails className="px-4" />}
      />
      <Route
        path={routes.futureReceiptsAnticipationFilters}
        element={<AnticipationFilters className="p-4" />}
      />
      <Route
        path={routes.futureReceiptsAnticipationReceipt}
        element={<AnticipationReceipt className="p-4" />}
      />
    </Switch>
  );
};
