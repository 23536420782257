import styled from "styled-components";
import { EBreakPoints } from "../../@types";

export const Container = styled.div({
  minHeight: "100vh",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [EBreakPoints.mobile]: {
    height: "100%",
    width: "100%",
  },
});
