import { Skeleton as VitalSkeleton } from "@hyperlocal/vital";
import { v4 } from "uuid";
import { Card } from "../../../../components";
import * as Styles from "./Display.styles";
import { SkeletonItem } from "./SkeletonItem";

export function Skeleton() {
  return (
    <Styles.Container>
      <Card
        isloading
        avatar={{
          icon: "MoneyDollarCircle",
        }}
        title=""
        subTitle=""
        value={0}
      />

      <Styles.ListContainer>
        <Styles.List>
          <Styles.ListHeadings>
            <h5>
              <VitalSkeleton width="225px" height="16px" />
            </h5>
            <p>
              <VitalSkeleton width="225px" height="16px" />
            </p>
          </Styles.ListHeadings>
          {Array.from({ length: 6 }).map(() => (
            <SkeletonItem key={v4()} />
          ))}
        </Styles.List>
      </Styles.ListContainer>
    </Styles.Container>
  );
}
