import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Container = styled.article((props) => ({
  position: "relative",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,

  backgroundColor: props.theme.palette.neutral.white,

  padding: props.theme.spacings.stack.x2s,

  h4: {
    [EBreakPoints.desktop]: {
      display: "none",
    },
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.xs,
    fontWeight: props.theme.fontWeights.bold,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
    alignSelf: "flex-start",
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    height: "calc(100vh - 56px)",
    minWidth: "100vw",
    border: 0,

    position: "initial",
    padding: props.theme.spacings.stack.x3s,
  },
}));

export const Body = styled.div(() => ({
  width: "100%",
}));

export const HeaderContainer = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  button: {
    backgroundColor: "red",
    padding: 0,
    height: 0,
  },
}));

export const ChipsContainer = styled.div((props) => ({
  width: "100%",
  minHeight: 32,

  display: "flex",
  gap: props.theme.spacings.inline.x2s,

  overflow: "auto",
  whiteSpace: "nowrap",

  "::-webkit-scrollbar": {
    display: "none",
  },

  marginBottom: props.theme.spacings.stack.x2s,

  [EBreakPoints.mobile]: {
    marginTop: props.theme.spacings.stack.x3s,
  },
}));

export const FiltersContainer = styled.div((props) => ({
  width: "100%",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: props.theme.spacings.stack.x3s,

  [EBreakPoints.mobile]: {
    gap: 0,
  },

  "& div > div:has(input[type=text-search])": {
    [EBreakPoints.mobile]: {
      display: "none",
    },
  },

  "& h5": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.sm,
    fontWeight: props.theme.fontWeights.bold,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,

    [EBreakPoints.mobile]: {
      fontSize: props.theme.fontSizes.xs,
      marginBottom: props.theme.spacings.stack.x3s,
    },
  },

  "& input": {
    boxSizing: "content-box",
  },

  "& button": {
    [EBreakPoints.mobile]: {
      width: "100%",
    },
    // width: "fit-content",
  },
}));

export const DateInputsContainer = styled.div((props) => ({
  width: "100%",

  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",

  justifyContent: "flex-start",

  gap: props.theme.spacings.stack.xs,

  input: {
    boxSizing: "content-box",
  },

  [EBreakPoints.mobile]: {
    gap: props.theme.spacings.inline.x2s,

    "& input": {
      boxSizing: "border-box",
      height: "auto",
      width: "100%",
    },
  },
}));

export const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",

  marginTop: "320px",

  [EBreakPoints.mobile]: {
    width: "100%",

    marginTop: 0,

    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      width: "100%",
      height: 48,
    },
  },
});
