/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect } from "react";
// @ts-ignore
import { Hooks, setHeader } from "@hyperlocal/banking-utility";
import { useNavigate } from "react-router-dom";
import { Filters as FiltersComponent } from "../components";

export function Filters() {
  const navigate = useNavigate();
  const isMobile = Hooks.useMediaQuery("mobile");

  const headerConfig = useCallback(() => {
    if (isMobile) {
      setHeader({
        title: "Filtros",
        leftIcon: {
          name: "SettingTrashClose",
          onClick: () => {
            navigate(-1);
          },
        },
      });
    } else {
      setHeader({});
    }
  }, [isMobile, navigate]);

  useEffect(() => {
    headerConfig();
    return () => {
      headerConfig();
    };
  }, [headerConfig]);

  return <FiltersComponent />;
}
