import Icon from "@hyperlocal/vital-icons";
import { useTheme } from "styled-components";
import { formatToBRL } from "../../../../../utils";
import { parseCardBrandIcon } from "../../../utils";
import { IDisclosureProps } from "../Disclosure";
import * as Styles from "../Disclosure.styles";

export function NegativeDisclosure({
  item,
  expanded,
  handleOpenDisclosure,
}: IDisclosureProps) {
  const { palette } = useTheme();

  return (
    <Styles.ListItem
      key={item.guid_onlead_item}
      data-expanded={expanded}
      className="negative"
      role="button"
      onClick={() => {
        handleOpenDisclosure(item.guid_onlead_item);
      }}
    >
      <Styles.ListItemHeader>
        <Styles.IconContainer className="negative">
          <Icon
            name={parseCardBrandIcon(item.brand_description)}
            data-state={item.brand_description.toLocaleLowerCase()}
            color={palette.primary.main}
          />
        </Styles.IconContainer>
        <div>
          <p className="negative">Taxa de Repasse</p>
          <p>{item?.nsu}</p>
        </div>
        <div>
          <p className="negative">{formatToBRL(item?.net_value)}</p>
        </div>
        <button type="button">
          <Icon name="ArrowArrowNoLineRight" data-expanded={expanded} />
        </button>
      </Styles.ListItemHeader>
      <Styles.ListItemInfo>
        <Styles.ListItemInfoRow>
          <p>Valor</p>
          <p className="negative">{formatToBRL(item?.gross_value)}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Data</p>
          <p>{new Date(item?.transaction_date).toLocaleDateString()}</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>Tipo da Transação</p>
          <p>Taxa de Repasse</p>
        </Styles.ListItemInfoRow>
        <Styles.ListItemInfoRow>
          <p>NSU</p>
          <p>{item.nsu}</p>
        </Styles.ListItemInfoRow>
        <div>
          <p>id da Operação</p>
          <p>{item?.guid_onlead_item}</p>
        </div>
      </Styles.ListItemInfo>
    </Styles.ListItem>
  );
}
