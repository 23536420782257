import { useCallback, useEffect, useState } from "react";
import {
  CalendarItem,
  useCalendarItem,
} from "@/features/futureReceipts/api/useCalendarItem";
import {
  formatCurrency,
  getDashboardRoutesData,
  getFirstAndLastDayOfMonth,
  getSalesRoutesData,
  Hooks,
  setHeader,
} from "@/utils";
import { Sheet } from "@hyperlocal/vital2";
import { isSameDay } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { Layout } from "../../../components";
import { formatToAPI } from "../../../utils/date/formatToAPI";
import { useGetReceivables } from "../api";
import { Calendar, CalendarEvents } from "../components/Calendar";
import { CalendarDrawer } from "../components/CalendarDrawer";
import { CalendarHeader, SelectionMode } from "../components/CalendarHeader";
import { CalendarResume } from "../components/CalendarResume";
import CalendarTable from "../components/CalendarTable";
import * as S from "./Calendar.styles";

const TODAY = new Date();

type Range = {
  start: Date | null;
  end: Date | null;
};

const groupCalendarEvents = (calendarData?: CalendarItem): CalendarEvents => {
  const groupedResult = calendarData?.items.reduce<CalendarEvents>(
    (list, item) => {
      const processingDate = new Date(item.processing_date);

      const event = list.find((calendarItem) =>
        isSameDay(processingDate, new Date(calendarItem.start)),
      );

      if (!event) {
        list.push({
          allDay: false,
          end: processingDate,
          start: processingDate,
          title: item.net_value,
        });

        return list;
      }

      if (typeof event.title === "number") event.title += item.net_value;

      return list;
    },
    [],
  );

  const parsedTitle = groupedResult?.map((item) => ({
    ...item,
    title: formatCurrency(typeof item.title === "number" ? item.title : 0),
  }));

  return parsedTitle;
};

export const CalendarRoute = () => {
  const [date, setDate] = useState(TODAY);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { firstDay, lastDay } = getFirstAndLastDayOfMonth(date);

  const { data: calendarData, isLoading: calendarLoading } = useCalendarItem({
    status: "SCHEDULED",
    startDate: formatToAPI(firstDay),
    endDate: formatToAPI(lastDay),
  });

  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedRange, setSelectedRange] = useState<Range | null>(null);

  const [selectionMode, setSelectionMode] = useState<SelectionMode>("SINGLE");

  const { isLoading: loadingReceivables } = useGetReceivables();

  const loadingData = loadingReceivables || calendarLoading;

  const backToDashboard = useCallback(() => {
    navigateToUrl(getSalesRoutesData().dashboard);
  }, []);

  useEffect(() => {
    setHeader({
      title: "Agenda futura",
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToDashboard,
      },
    });
    return () => {
      setHeader({});
    };
  }, [backToDashboard]);

  const calendarEvents = groupCalendarEvents(calendarData);

  const handleSelectionModeChange = (isRangeMode: boolean) => {
    setSelectionMode(isRangeMode ? "RANGE" : "SINGLE");
  };

  const handleSelectRange = (range: Range) => {
    setSelectedRange(range);

    if (!isMobile) {
      return setDrawerOpen(true);
    }

    if (selectionMode === "RANGE" && range.start && range.end) {
      const queryParams = new URLSearchParams();
      queryParams.set("start", formatToAPI(range.start));
      queryParams.set("end", formatToAPI(range.end));

      return navigate(
        `${location.pathname}/details/?${queryParams.toString()}`,
      );
    }

    if (selectionMode === "SINGLE") {
      const queryParams = new URLSearchParams();
      queryParams.set("start", formatToAPI(range.start));
      queryParams.set("end", formatToAPI(range.end));

      return navigate(
        `${location.pathname}/details/?${queryParams.toString()}`,
      );
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [drawerOpen]);

  return (
    <S.Wrapper>
      <Layout
        title="Agenda futura"
        breadcrumb={[
          { label: "Pay", route: getDashboardRoutesData().dashboardPay },
          { label: "Agenda futura" },
        ]}
        backTo={getDashboardRoutesData().dashboardPay}
        onBreadcrumbClick={() => navigate(getDashboardRoutesData().dashboard)}
      >
        <S.Container>
          <CalendarResume
            selectedDate={date}
            isLoadingCalendarItems={loadingData}
          />

          <S.CalendarContainer>
            <CalendarHeader
              date={date}
              handleNextMonth={(date) => setDate(date)}
              handlePrevMonth={(date) => setDate(date)}
              handleCurrentMonth={(date) => setDate(date)}
              selectionMode={selectionMode}
              handleSelectionModeChange={handleSelectionModeChange}
            />

            <Calendar
              key={date.toString()}
              date={date}
              events={calendarEvents}
              onSelectRange={handleSelectRange}
              selectedRange={selectedRange}
              selectionMode={selectionMode}
            />

            <CalendarTable
              data={calendarData}
              loading={loadingData}
              date={date}
              onSelectRange={handleSelectRange}
              selectedRange={selectedRange}
              selectionMode={selectionMode}
            />
          </S.CalendarContainer>
        </S.Container>
      </Layout>

      <Sheet.Root
        open={drawerOpen}
        onOpenChange={setDrawerOpen}
        key="calendar-drawer"
      >
        <Sheet.Content>
          {selectedRange && !isMobile && (
            <CalendarDrawer
              start={selectedRange.start}
              end={selectedRange.end}
              onClose={() => setDrawerOpen(false)}
            />
          )}
        </Sheet.Content>
      </Sheet.Root>
    </S.Wrapper>
  );
};
