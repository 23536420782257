import { ExportFilesDrawer } from "../../../../components";
import { useSales } from "../../api";
import { useFilters } from "../../stores";

interface IExportProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Export({ isOpen, onClose }: IExportProps) {
  const [currentState] = useFilters();

  const {
    query: { data: sales },
  } = useSales({
    startDate: currentState.startDate.toISOString().split("T")[0],
    endDate: currentState.endDate.toISOString().split("T")[0],
    status: "SCHEDULED",
    config: {
      enabled: !!currentState.startDate && !!currentState.endDate,
    },
  });

  const documentBody = sales?.items?.reduce((acc, item) => {
    const body = {
      "Data de processamento": item.processing_date,
      "Data da transação": item.transaction_date,
      "Valor bruto(R$)": item.gross_value.toFixed(2),
      Parcela: item.installment_number,
      "Nº de parcelas": item.total_installments,
      Tipo: item.transaction_product,
      Operação: item.type,
      Destino: "Conta digital",
      "Taxa da transação(R$)": item.discount_value.toFixed(2),
      "Taxa da antecipação(R$)": item.advance_value.toFixed(2),
      "Valor líquido(R$)": item.net_value.toFixed(2),
      Bandeira: item.brand_description,
      NSU: item.nsu,
      Terminal: item.terminal_serial_number,
    };

    return acc.concat(body);
  }, []);

  return (
    <ExportFilesDrawer
      isOpen={isOpen}
      onClose={onClose}
      fileName="recebimentos_futuros"
      data={documentBody}
    />
  );
}
