/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getReceivedRoutesData } from "@hyperlocal/banking-utility";
import { Route, Routes as Switch } from "react-router-dom";
import { Filters } from "./Filters";
import { ReceivedValueDetail } from "./ReceivedValueDetail";
import { ReceivedValues } from "./ReceivedValues";

const routes = getReceivedRoutesData();

export const ReceivedRoutes = () => {
  return (
    <Switch>
      <Route index path={routes.received} element={<ReceivedValues />} />
      <Route path={routes.receivedDetails} element={<ReceivedValueDetail />} />
      <Route path={routes.receivedFillters} element={<Filters />} />
    </Switch>
  );
};
