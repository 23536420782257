import { useEffect } from "react";
import {
  formatCurrency,
  getDashboardRoutesData,
  getReceivedRoutesData,
  Hooks,
  setHeader,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Breadcrumb, BreadCrumbItem } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { useAnticipation } from "../../api";
import { AnticipationFilters } from "./filters";
import { AnticipationList } from "./list";

export const Anticipation = () => {
  const routes = getReceivedRoutesData();
  const dashboardRoutes = getDashboardRoutesData();
  const navigate = useNavigate();
  const isMobile = Hooks.useMediaQuery("mobile");

  const { data, isLoading } = useAnticipation();

  useEffect(() => {
    isMobile &&
      setHeader({
        title: "Pedidos de antecipação",
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: () => navigate(routes.futureReceipts),
        },
      });
    return () => {
      setHeader({});
    };
  }, [isMobile, navigate, routes]);

  const totalMonthAnticipation = data?.spotAnticipationRequest?.reduce(
    (acc, current) => acc + current.prepaymentGrossAmountRequested,
    0,
  );

  return (
    <>
      <div className="hidden h-screen max-h-screen overflow-y-auto bg-neutral-lighter py-6 font-base desktop:block">
        <div className="mx-auto flex h-full w-full max-w-[1248px] flex-col gap-stack-x2s px-16">
          <Breadcrumb>
            <BreadCrumbItem
              onClick={() => navigate(dashboardRoutes.dashboardPay)}
            >
              Pay
            </BreadCrumbItem>

            <BreadCrumbItem onClick={() => navigate(routes.futureReceipts)}>
              Recebimentos
            </BreadCrumbItem>

            <BreadCrumbItem active>Pedidos de antecipação</BreadCrumbItem>
          </Breadcrumb>

          <div className="flex items-center gap-2">
            <button
              className="flex h-6 w-6 cursor-pointer items-center justify-center"
              onClick={() => navigate(routes.futureReceipts)}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.824.824 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <h1 className="text-lg/none font-bold text-neutral-darkest">
              Pedidos de antecipação
            </h1>
          </div>

          <section className="relative grid h-full grid-cols-2 gap-stack-x2s">
            <AnticipationFilters className="h-full w-full rounded-lg bg-white p-6" />

            <div className="relative">
              <div className="absolute inset-0 flex flex-col space-y-4">
                <div className="flex items-end justify-between rounded-sm bg-white p-6">
                  <div className="flex items-center gap-4">
                    <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-primary-lighter">
                      <Icon
                        name="MoneyAntecipate"
                        className="size-[22px] fill-primary-main"
                      />
                    </div>

                    <div>
                      <h5 className="text-neutral-darkest">
                        Antecipações solicitadas
                      </h5>

                      <p className="text-neutral-dark">
                        {isLoading
                          ? "0"
                          : data?.spotAnticipationRequest?.length}{" "}
                        antecipações
                      </p>
                    </div>
                  </div>

                  <p className="text-md font-bold text-primary-main">
                    <span className="text-xs">R$</span>{" "}
                    {isLoading
                      ? "--"
                      : formatCurrency(totalMonthAnticipation).replace(
                          "R$",
                          "",
                        )}
                  </p>
                </div>

                <AnticipationList className="h-full rounded-sm bg-white p-squished-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="flex min-h-screen flex-col desktop:hidden">
        <AnticipationList className="h-full rounded-sm bg-white p-squished-lg" />
      </div>
    </>
  );
};
