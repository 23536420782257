import styled from "styled-components";

export const CalendarWrapper = styled.div(
  ({ theme: { palette, fontSizes, borderRadius, spacings } }) => ({
    width: "100%",
    height: "100%",

    ".rbc-month-view": {
      border: "none",
    },

    ".rbc-month-row + .rbc-month-row": {
      borderTopStyle: "solid",
      borderTopWidth: "2px",
      borderTopColor: palette.neutral.lighter,
    },

    ".rbc-header": {
      paddingBottom: "8px",

      borderBottomStyle: "solid",
      borderBottomWidth: "2px",
      borderBottomColor: palette.neutral.lighter,
    },

    ".rbc-header + .rbc-header": {
      borderLeftStyle: "solid",
      borderLeftWidth: "2px",
      borderLeftColor: palette.neutral.lighter,
    },

    ".rbc-date-cell": {
      display: "flex",
      justifyContent: "center",
      fontSize: fontSizes.xs,
      color: palette.neutral.darkest,

      paddingTop: spacings.stack.quarck,
    },

    ".rbc-date-cell button": {
      pointerEvents: "none",
    },

    ".rbc-off-range-bg": {
      background: "transparent",
    },

    ".rbc-off-range": {
      color: palette.neutral.main,
    },

    ".rbc-day-bg + .rbc-day-bg": {
      borderLeftStyle: "solid",
      borderLeftWidth: "2px",
      borderLeftColor: palette.neutral.lighter,
    },

    ".rbc-day-bg.rbc-day-selected": {
      background: palette.primary.light,

      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: palette.primary.main,
    },

    ".rbc-day-bg.rbc-day-in-range": {
      background: palette.primary.lighter,
    },

    ".rbc-today": {
      background: "transparent",
    },

    ".rbc-now button": {
      fontWeight: "bold",
      color: palette.neutral.white,
      background: palette.primary.main,
      borderRadius: borderRadius.circle,
      width: "24px",
      height: "24px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    ".rbc-row-content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      height: "100%",
    },

    ".rbc-row-segment": {
      padding: spacings.inset.x2s,
      marginTop: spacings.stack.x2s,
    },

    ".rbc-event": {
      fontSize: fontSizes.x2s,
      padding: spacings.inset.x3s,
      textAlign: "center",

      outline: "none !important",
      border: "none",
    },

    ".rbc-event-past, .rbc-event-past.rbc-selected, .rbc-event-current": {
      background: palette.neutral.lighter,
      color: palette.neutral.dark,
    },

    ".rb-event-today, .rb-event-today.rbc-selected": {
      color: palette.primary.darker,
      background: palette.primary.lighter,
    },

    ".rbc-event-future, .rbc-event-future.rbc-selected": {
      color: palette.neutral.darkest,
      background: palette.neutral.lighter,
    },

    ".rbc-event.rbc-selected": {
      background: palette.neutral.lighter,
    },
  }),
);
