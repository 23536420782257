export const formatToBRL = (value: number, withoutCode?: boolean) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  if (withoutCode) {
    return formatter.format(value).replace("R$", "").trim();
  }
  return formatter.format(value);
};
