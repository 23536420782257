/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Drawer, Card as VitalCard } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useDownloadDocument } from "../../api";
import excelIcon from "../../assets/apk_document.svg";
import pdfIcon from "../../assets/picture_as_pdf.svg";
import * as Styles from "./ExportFilesDrawer.styles";

interface IExportFilesModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  data: T;
  onSuccess?: () => void;
}

type IExcelExtension = {
  name: "Excel";
  extension: "xlsx";
};

type IPDFExtension = {
  name: "PDF";
  extension: "pdf";
};

type IExtension = IExcelExtension | IPDFExtension;

const EXTENSIONS: Array<IExtension> = [
  {
    name: "Excel",
    extension: "xlsx",
  },
  {
    name: "PDF",
    extension: "pdf",
  },
];

const ICONS = {
  Excel: excelIcon,
  PDF: pdfIcon,
};

export function ExportFilesDrawer<T>({
  isOpen,
  onClose,
  fileName,
  data,
  onSuccess,
}: IExportFilesModalProps<T>) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { mutateAsync } = useDownloadDocument();
  async function handleExport(extension: IExtension["extension"]) {
    await mutateAsync(
      {
        extension,
        body: data,
        name: fileName,
      },
      {
        onSuccess,
      },
    );
  }

  return (
    <Drawer
      open={isOpen}
      onDismiss={onClose}
      position={isMobile ? "bottom" : "right"}
      title="Exportar Valores Recebidos"
    >
      <Styles.Container>
        <h5>Escolha um formato:</h5>
        {EXTENSIONS.map((item) => (
          <VitalCard key={item.name}>
            <Styles.ButtonContainer
              onClick={async () => {
                await handleExport(item.extension);
              }}
            >
              <div>
                <img src={ICONS[item.name]} alt="" />
                <p>{item.name}</p>
              </div>
              <div className="iconWrapper">
                <Icon name="ArrowArrowNoLineRight" />
              </div>
            </Styles.ButtonContainer>
          </VitalCard>
        ))}
      </Styles.Container>
    </Drawer>
  );
}
