import styled from "styled-components";

export const Header = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  margin: `${spacings.stack.nano} 0`,
}));

export const Date = styled.h6(({ theme: { fontSizes, fontWeights } }) => ({
  fontSize: fontSizes.xs,
  fontWeight: fontWeights.bold,
}));

export const Weekday = styled.p(({ theme: { fontSizes, palette } }) => ({
  fontSize: fontSizes.x2s,
  color: palette.neutral.dark,
}));

export const SkeletonList = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: spacings.stack.x3s,
}));
