import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { Skeleton as VitalSkeleton } from "@hyperlocal/vital";
import * as Styles from "./Display.styles";

type ISkeletonItemProps = React.ComponentProps<"li">;

export const BaseSkeletonItem: ForwardRefRenderFunction<
  HTMLLIElement,
  ISkeletonItemProps
> = (props, ref) => {
  return (
    <Styles.ListItem {...props} ref={ref}>
      <button type="button">
        <Styles.ListItemInfo>
          <div>
            <p>
              <VitalSkeleton width="150px" height="16px" />
            </p>
            <p>
              <VitalSkeleton width="150px" height="16px" />
            </p>
          </div>
          <div>
            <p>
              <VitalSkeleton width="150px" height="16px" />
            </p>
            <p>
              <VitalSkeleton width="150px" height="16px" />
            </p>
          </div>
        </Styles.ListItemInfo>
      </button>
    </Styles.ListItem>
  );
};

export const SkeletonItem = forwardRef(BaseSkeletonItem);
